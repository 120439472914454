import { State } from 'redux/store';
import { RoutePaths } from 'constants/routePaths';
import React, { ComponentType, FC } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { selectDisabledRoutes } from 'redux/settings/selectors';

const PrivateRoute: FC<RouteProps> = ({ render, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return localStorage.getItem('access_token') ? (
          render && render(props)
        ) : (
          <Redirect
            to={{
              pathname: RoutePaths.Login,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

interface Props extends RouteProps {
  component: ComponentType<any>;
  disabledRoutes: string[];
  protected?: boolean;
  id?: string;
  layout: ComponentType<any>;
}

const RouteWithLayout: FC<Props> = props => {
  const { disabledRoutes, layout: Layout, component: Component, ...rest } = props;
  // eslint-disable-next-line react/destructuring-assignment
  const RouteAuthenication = props.protected === false ? Route : PrivateRoute;

  return disabledRoutes?.length && disabledRoutes.some(disabledRoute => disabledRoute === props.id) ? (
    <Redirect to="/not-found" />
  ) : (
    <RouteAuthenication
      {...rest}
      render={(matchProps: RouteComponentProps) => {
        return (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        );
      }}
    />
  );
};

const mapStateToProps = (state: State) => ({
  disabledRoutes: selectDisabledRoutes(state),
});

export default connect(mapStateToProps)(RouteWithLayout);
