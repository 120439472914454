import { BaseQueryFn, FetchBaseQueryError, fetchBaseQuery, createApi, FetchArgs } from '@reduxjs/toolkit/query/react';
import { State } from 'redux/store';
import { history } from 'redux/history';
import getApiConfig from 'api/apiConfig';
import { RoutePaths } from 'constants/routePaths';
import { selectUserWarehouseId } from 'redux/user/userSlice';

const apiConfig = getApiConfig();

export const baseQuery = fetchBaseQuery({
  baseUrl: apiConfig.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const warehouseId = selectUserWarehouseId(getState() as State);
    const updatedConfig = getApiConfig(warehouseId);
    Object.entries(updatedConfig.headers).forEach(([key, value]) => {
      headers.set(key, value);
    });
    return headers;
  },
});
export const baseQueryWithAuthError: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('token_bearer');
    localStorage.removeItem('expires_in');
    history.push(RoutePaths.Login);
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithAuthError,
  endpoints: () => ({}),
});
