import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserJSON, UsersConfigurationsJSON } from './types';

const activeEmployee = createSlice({
  name: 'activeEmployee',
  initialState: null as UserJSON | null,
  reducers: {
    setActiveEmployee: (_state, { payload }: PayloadAction<UserJSON | null>) => payload,
  },
});

const activeId = createSlice({
  name: 'activeId',
  initialState: null as string | null,
  reducers: {
    setActiveEmployeeId: (_state, { payload }: PayloadAction<string | null>) => payload,
  },
});

const employeesCount = createSlice({
  name: 'employeesCount',
  initialState: null as UsersConfigurationsJSON | null,
  reducers: {
    setEmployeesCount: (_state, { payload }: PayloadAction<UsersConfigurationsJSON>) => payload,
  },
});

export const { setActiveEmployee } = activeEmployee.actions;
export const { setActiveEmployeeId } = activeId.actions;
export const { setEmployeesCount } = employeesCount.actions;

export default combineReducers({
  activeId: activeId.reducer,
  activeEmployee: activeEmployee.reducer,
  employeesCount: employeesCount.reducer,
});
