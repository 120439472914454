import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { closeSnackbar } from 'redux/snackbar/reducers';
import { Theme, Alert as MuiAlert, AlertProps } from '@mui/material';

const Alert: FC<AlertProps> = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme: Theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
}));

export type Severity = 'success' | 'info' | 'warning' | 'error';

interface Props {
  isVisible: boolean;
  anchorOrigin: SnackbarOrigin;
  message: string;
  handleClose?: () => void;
  severity: Severity;
}

const SnackbarMessage: FC<Props> = ({ isVisible, anchorOrigin, message, handleClose, severity = 'success' }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      {message && (
        <Snackbar
          anchorOrigin={anchorOrigin}
          open={isVisible}
          autoHideDuration={5000}
          disableWindowBlurListener
          onClose={() => dispatch(closeSnackbar())}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{message}</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" className={classes.close} onClick={handleClose}>
              <CloseIcon />
            </IconButton>,
          ]}
        >
          <div>
            <Alert severity={severity}>{message}</Alert>
          </div>
        </Snackbar>
      )}
    </>
  );
};

export default SnackbarMessage;
