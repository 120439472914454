import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ActionId, ActionImpl } from 'kbar';
import React from 'react';

const useStyles = makeStyles<Theme, { active: boolean; selected: boolean }>(_theme => ({
  itemContainer: {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  itemContainerStates: {
    borderLeft: ({ active }) => `2px solid ${active ? ' rgb(28 28 29)' : 'transparent'}`,
    background: ({ active, selected }) =>
      active && selected
        ? 'rgba(0, 79, 198, 0.09)'
        : active
        ? 'rgba(0 0 0 / 0.05)'
        : selected
        ? 'rgba(0, 79, 198, 0.04)'
        : 'transparent',
  },
  item: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: 14,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  ancestorName: {
    opacity: 0.5,
    marginRight: 8,
  },
  arrow: {
    marginRight: 8,
  },
  subtitle: {
    fontSize: 12,
  },
  shortcuts: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: '4px',
  },
  shortcutItem: {
    padding: '4px 6px',
    background: 'rgba(0 0 0 / .1)',
    borderRadius: '4px',
    fontSize: 14,
  },
}));

const KBarResultsItem = React.forwardRef(
  (
    {
      action,
      active,
      currentRootActionId,
      selected,
    }: {
      action: ActionImpl;
      active: boolean;
      currentRootActionId: ActionId;
      selected: boolean;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const classes = useStyles({ active, selected });
    const ancestors = React.useMemo(() => {
      if (!currentRootActionId) return action.ancestors;
      const index = action.ancestors.findIndex(ancestor => ancestor.id === currentRootActionId);
      return action.ancestors.slice(index + 1);
    }, [action.ancestors, currentRootActionId]);

    return (
      <div ref={ref} className={`${classes.itemContainer} ${classes.itemContainerStates}`}>
        <div className={classes.item}>
          {action.icon && action.icon}
          <div className={classes.content}>
            <div>
              {ancestors.length > 0 &&
                ancestors.map(ancestor => (
                  <React.Fragment key={ancestor.id}>
                    <span className={classes.ancestorName}>{ancestor.name}</span>
                    <span className={classes.arrow}>&rsaquo;</span>
                  </React.Fragment>
                ))}
              <span>{action.name}</span>
            </div>
            {action.subtitle && <span className={classes.subtitle}>{action.subtitle}</span>}
          </div>
        </div>
        {action.shortcut?.length ? (
          <div aria-hidden className={classes.shortcuts}>
            {action.shortcut.map((sc, i) => (
              <kbd key={sc + i} className={classes.shortcutItem}>
                {sc}
              </kbd>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
);

export default KBarResultsItem;
