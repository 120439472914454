import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { MonthlyWorkUnitsResponse, OrdersConversionsResponse } from './types';

type InitialManufacturingOrdersState = MonthlyWorkUnitsResponse | null;
const initialManufacturingOrdersState = null as InitialManufacturingOrdersState;

const manufacturingOrders = createSlice({
  name: 'manufacturingOrders',
  initialState: initialManufacturingOrdersState,
  reducers: {
    setMonthlyWorkUnits: (_state, { payload }: PayloadAction<MonthlyWorkUnitsResponse>) => payload,
  },
});

type InitialSalesOrdersState = OrdersConversionsResponse | null;
const initialSalesOrdersState = null as InitialSalesOrdersState;

const salesOrders = createSlice({
  name: 'salesOrders',
  initialState: initialSalesOrdersState,
  reducers: {
    setOrdersConversions: (_state, { payload }: PayloadAction<OrdersConversionsResponse>) => payload,
  },
});

export const { setMonthlyWorkUnits } = manufacturingOrders.actions;
export const { setOrdersConversions } = salesOrders.actions;

export default combineReducers({
  manufacturingOrders: manufacturingOrders.reducer,
  salesOrders: salesOrders.reducer,
});
