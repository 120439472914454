import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { InventoryResponse } from 'redux/inventory/apiTypes';
import { removeObjectFromArray, updateObjectInArray } from 'redux/utils';
import {
  CompletedInternalTransferProduct,
  InternalOperationOrderProductResponse,
  InternalOperationOrderResponse,
} from 'types/internalTransfers';

export const resetInternalTransferState = createAction('resetInternalTransferState');

type InitialOrderState = InternalOperationOrderResponse | null;
const activeOrderInitialState = null as InitialOrderState;

const activeOrder = createSlice({
  name: 'activeOrder',
  initialState: activeOrderInitialState,
  reducers: {
    setActiveInternalTransfer: (_, { payload }: PayloadAction<InternalOperationOrderResponse | null>) => payload,
    updateActiveInternalTransfer: (state, { payload }: PayloadAction<Partial<InternalOperationOrderResponse>>) => {
      if (state) {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(resetInternalTransferState, () => activeOrderInitialState);
  },
});
export const { setActiveInternalTransfer, updateActiveInternalTransfer } = activeOrder.actions;

type InitialActiveIdState = string | null;
const activeIdState = null as InitialActiveIdState;

const activeId = createSlice({
  name: 'activeId',
  initialState: activeIdState,
  reducers: {
    setActiveInternalTransferId: (_, { payload }: PayloadAction<string | null>) => payload,
  },
  extraReducers: builder => {
    builder.addCase(resetInternalTransferState, () => activeIdState);
  },
});
export const { setActiveInternalTransferId } = activeId.actions;

type InitialIPProductsState = InternalOperationOrderProductResponse[] | null;
const productsInitialState = null as InitialIPProductsState;
const products = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setInternalTransferProducts: (_state, { payload }: PayloadAction<InternalOperationOrderProductResponse[] | null>) =>
      payload,
    addInternalTransferProduct: (state, { payload }: PayloadAction<InternalOperationOrderProductResponse>) => {
      if (!state) {
        return [payload];
      } else {
        return [...state, payload];
      }
    },
    updateInternalTransferProduct: (state, { payload }: PayloadAction<InternalOperationOrderProductResponse>) =>
      updateObjectInArray(state, { payload }),
    deleteInternalTransferProduct: (state, { payload }: PayloadAction<{ id: number }>) =>
      removeObjectFromArray(state, { payload }),
  },
  extraReducers: builder => {
    builder.addCase(resetInternalTransferState, () => productsInitialState);
  },
});
export const {
  updateInternalTransferProduct,
  setInternalTransferProducts,
  addInternalTransferProduct,
  deleteInternalTransferProduct,
} = products.actions;

type InitialReservedInventoryState = InventoryResponse[] | null;
const reservedInventoryState = null as InitialReservedInventoryState;

const reservedInventory = createSlice({
  name: 'reservedInventory',
  initialState: reservedInventoryState,
  reducers: {
    setInternalTransferReservedInventory: (_state, { payload }: PayloadAction<InventoryResponse[] | null>) => payload,
  },
  extraReducers: builder => {
    builder.addCase(resetInternalTransferState, () => reservedInventoryState);
  },
});
export const { setInternalTransferReservedInventory } = reservedInventory.actions;

type InitialCompletedProductsState = CompletedInternalTransferProduct[] | null;
const completedProductsState = null as InitialCompletedProductsState;

const completedProducts = createSlice({
  name: 'completedProducts',
  initialState: completedProductsState,
  reducers: {
    setInternalTransferCompletedProducts: (
      _state,
      { payload }: PayloadAction<CompletedInternalTransferProduct[] | null>
    ) => payload,
  },
  extraReducers: builder => {
    builder.addCase(resetInternalTransferState, () => completedProductsState);
  },
});
export const { setInternalTransferCompletedProducts } = completedProducts.actions;

export default combineReducers({
  products: products.reducer,
  activeId: activeId.reducer,
  activeOrder: activeOrder.reducer,
  reservedInventory: reservedInventory.reducer,
  completedProducts: completedProducts.reducer,
});
