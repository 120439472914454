import ReactGA from 'react-ga4';

export const getUserIdentity = (idToken: string | null) => {
  if (!idToken) {
    return;
  }
  const payloadB64 = idToken.split('.')[1];
  return JSON.parse(atob(payloadB64));
};

export const initializeGoogleAnalytics = () => {
  const userIdentity = getUserIdentity(localStorage.getItem('id_token'));
  const currentEnv = process.env.REACT_APP_ENV;
  const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;
  GA_MEASUREMENT_ID
    ? (ReactGA.initialize(GA_MEASUREMENT_ID, {
        gaOptions: { userId: `${userIdentity?.zoneinfo}+${userIdentity?.sub}` },
      }),
      console.log('GA initialized', currentEnv))
    : console.log('GA_ID not found', currentEnv);
};

export const trackCustomGAEvent = (category: GAEventCategory, action: GAEventAction, label?: string) => {
  return ReactGA.event({
    category,
    action,
    label,
  });
};

export enum GAEventCategory {
  SEARCH_BAR = 'SearchBar',
}

export enum GAEventAction {
  CLICK_BUTTON = 'ClickButton',
  TYPE_QUERY = 'TypeQuery',
  SELECT_RESULT = 'SelectResult',
}
