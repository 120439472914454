import { combineReducers, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState } from 'material-react-table';

const dataFetch = createSlice({
  name: 'dataFetch',
  initialState: false,
  reducers: {
    setFetchTableData: (_state, { payload }) => payload,
  },
});
export const { setFetchTableData } = dataFetch.actions;

interface TableState {
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  pagination: MRT_PaginationState;
}

interface MRTState {
  [tableKey: string]: TableState;
}

const initialTableState: TableState = {
  columnFilters: [],
  sorting: [],
  pagination: { pageIndex: 0, pageSize: 15 },
};

const initialMRTState: MRTState = {};

const initializeTableState = createAsyncThunk(
  'mrtTable/initializeTableState',
  async ({ tableKey, customInitialState }: { tableKey: string; customInitialState?: TableState }, { getState }) => {
    const state: MRTState = (getState() as { table: { mrtTable: MRTState } }).table.mrtTable;
    if (!state[tableKey]) {
      return { tableKey, tableState: customInitialState || initialTableState };
    }
    return null;
  }
);

const mrtSlice = createSlice({
  name: 'mrtTable',
  initialState: initialMRTState,
  reducers: {
    updateTableState(state, action: PayloadAction<{ tableKey: string; tableState: TableState }>) {
      const { tableKey, tableState } = action.payload;
      state[tableKey] = tableState;
    },
    resetTableState(state, action: PayloadAction<{ tableKey: string; resetState?: TableState }>) {
      const { tableKey, resetState } = action.payload;
      state[tableKey] = resetState || initialTableState;
    },
    resetAllTablesStates(state) {
      Object.keys(state).forEach(tableKey => {
        if (state[tableKey]) {
          state[tableKey] = initialTableState;
        }
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(initializeTableState.fulfilled, (state, action) => {
      if (action.payload) {
        const { tableKey, tableState } = action.payload;
        state[tableKey] = tableState;
      }
    });
  },
});

export const { updateTableState, resetAllTablesStates, resetTableState } = mrtSlice.actions;
export { initializeTableState };

export default combineReducers({
  dataFetch: dataFetch.reducer,
  mrtTable: mrtSlice.reducer,
});
