import { BASE_WAREHOUSES_URL } from 'api/constants';
import { api } from 'redux/api/baseApi';
import { Warehouse } from 'modules/warehouses/types';
import { handleRtkQueryError } from 'redux/api/utils';
import { openSnackbar } from 'redux/snackbar/reducers';
import i18n from 'i18n';
import { buildUrlWithParams } from 'utils/buildUrlWithParams';
import { PaginatedAPIResponse } from 'types/paginatedApiResponse';
import { Parameter } from 'api';

const whApi = api.enhanceEndpoints({ addTagTypes: ['Warehouses', 'Warehouse'] }).injectEndpoints({
  endpoints: build => ({
    getWarehouses: build.query<PaginatedAPIResponse<Warehouse>, Parameter[] | void>({
      query: params => ({
        url:
          params && params.length > 0 ? buildUrlWithParams(`${BASE_WAREHOUSES_URL}`, params) : `${BASE_WAREHOUSES_URL}`,
      }),
      providesTags: ['Warehouses'],
    }),
    getWarehouse: build.query<Warehouse, string>({
      query: id => `${BASE_WAREHOUSES_URL}/${id}`,
      providesTags: (_, __, id) => [{ type: 'Warehouse', id }],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (err) {
          handleRtkQueryError(err, dispatch);
        }
      },
    }),
    createWarehouse: build.mutation<Warehouse, Omit<Warehouse, 'id' | 'updated_at'>>({
      query: data => ({
        url: `${BASE_WAREHOUSES_URL}`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;
          dispatch(openSnackbar({ message: i18n.t('Warehouse created'), severity: 'success' }));
          return result;
        } catch (err) {
          if (err.error.status !== 400) {
            handleRtkQueryError(err, dispatch, 'mutation');
          }
          return err;
        }
      },
    }),
    updateWarehouse: build.mutation<Warehouse, Warehouse>({
      query: data => ({
        url: `${BASE_WAREHOUSES_URL}/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSnackbar({ message: i18n.t('Warehouse updated'), severity: 'success' }));
        } catch (err) {
          if (err.error.status !== 400) {
            handleRtkQueryError(err, dispatch, 'mutation');
          }
          return err;
        }
      },
      invalidatesTags: [{ type: 'Warehouses' }],
    }),
    deleteWarehouse: build.mutation<void, number>({
      query: id => ({
        url: `${BASE_WAREHOUSES_URL}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, ___) => [{ type: 'Warehouses' }],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(openSnackbar({ message: i18n.t('Warehouse deleted'), severity: 'success' }));
        } catch (err) {
          handleRtkQueryError(err, dispatch, 'mutation');
        }
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetWarehouseQuery,
  useLazyGetWarehouseQuery,
  useUpdateWarehouseMutation,
  useCreateWarehouseMutation,
  useDeleteWarehouseMutation,
  useGetWarehousesQuery,
  useLazyGetWarehousesQuery,
} = whApi;
