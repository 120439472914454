import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pushToArray } from '../utils';
import { InventoryResponse } from './apiTypes';

type Data = {
  id: string;
};
type InitialDataState = Data[] | null;
const dataInitialState = null as InitialDataState;

const data = createSlice({
  name: 'data',
  initialState: dataInitialState,
  reducers: {
    createInventory: (state, { payload }: PayloadAction<Data>) => pushToArray(state, { payload }),
  },
});

const palletData = createSlice({
  name: 'palletData',
  initialState: dataInitialState,
  reducers: {
    createPallet: (state, { payload }: PayloadAction<Data>) => pushToArray(state, { payload }),
  },
});

const activePalletId = createSlice({
  name: 'activePalletId',
  initialState: null as number | null,
  reducers: {
    setActivePalletId: (_state, { payload }) => payload,
  },
});

const currentInventoryItem = createSlice({
  name: 'currentInventoryItem',
  initialState: null as InventoryResponse | null,
  reducers: {
    setCurrentInventoryItem: (_state, { payload }) => payload,
  },
});

export const { createInventory } = data.actions;
export const { createPallet } = palletData.actions;
export const { setActivePalletId } = activePalletId.actions;
export const { setCurrentInventoryItem } = currentInventoryItem.actions;

export default combineReducers({
  data: data.reducer,
  palletData: palletData.reducer,
  activePalletId: activePalletId.reducer,
  currentInventoryItem: currentInventoryItem.reducer,
});
