import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { State } from 'redux/store';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectSidebarPages } from 'redux/settings/selectors';
import { SidebarNav } from './components';
import { SidebarPage } from './types';
import { Theme } from '@mui/material';
import { HEADER_HEIGHT } from 'assets/styles/constants';

const drawerWidth = 'auto';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 200,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(10),
    },
  },
  nav: {
    marginTop: HEADER_HEIGHT,
    marginBottom: theme.spacing(2),
  },
}));

type Variant = 'permanent' | 'persistent' | 'temporary';

interface Props {
  onSidebarToggle: () => void;
  sidebarOpen: boolean;
  variant?: Variant;
}

const Sidebar: FC<Props> = ({ onSidebarToggle, sidebarOpen, variant }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const sidebarPages: SidebarPage[] = useSelector((state: State) => selectSidebarPages(state));
  const withTranslationSidebarPages = sidebarPages.map(page => {
    if (page?.items) {
      return {
        ...page,
        title: t(page.title),
        items: page.items.map(item => ({
          ...item,
          title: t(item.title),
        })),
      };
    }
    return {
      ...page,
      title: t(page.title),
    };
  });
  return (
    <Drawer
      variant={variant}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: sidebarOpen,
        [classes.drawerClose]: !sidebarOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: sidebarOpen,
          [classes.drawerClose]: !sidebarOpen,
        }),
      }}
      open={sidebarOpen}
      onClose={onSidebarToggle}
    >
      <SidebarNav
        className={classes.nav}
        pages={withTranslationSidebarPages}
        sidebarOpen={sidebarOpen}
        onSidebarToggle={onSidebarToggle}
      />
    </Drawer>
  );
};

export default Sidebar;
