export const DASHBOARD_SERVICE_URL = 'dashboard';
export const WAREHOUSE_SERVICE_URL = 'warehouse-service';
export const ORDERS_CONTACTS_SERVICE_URL = 'orders-contacts-service';
export const THIRD_PARTY_SERVICE_URL = 'third-party-service';
export const TASKS_SERVICE_URL = 'tasks-service';
export const SKU_SERVICE_URL = 'sku-service';
export const USERS_AND_REPORTING_SERVICE_URL = 'users-and-reporting-service';
export const FORECASTING_SERVICE_URL = 'forecasting-service';
export const WMS_API_SERVICE = 'wms-api';

export const BASE_LOCATIONS_URL = `${WAREHOUSE_SERVICE_URL}/locations`;
export const BASE_INVENTORY_URL = `${WAREHOUSE_SERVICE_URL}/inventory`;
export const BASE_PALLETS_URL = `${WAREHOUSE_SERVICE_URL}/pallets`;
export const BASE_WAREHOUSES_URL = `${WAREHOUSE_SERVICE_URL}/warehouses`;

export const FLAT_LOCATIONS_URL = `${WAREHOUSE_SERVICE_URL}/locations-flat`;
export const NESTED_LOCATIONS_URL = `${WAREHOUSE_SERVICE_URL}/locations-nested`;

export const LOCATION_CLASSES_URL = `${WAREHOUSE_SERVICE_URL}/location-classes`;
export const SKU_LOCATION_CLASSES_URL = `${WAREHOUSE_SERVICE_URL}/skus-location-classes`;

export const BASE_MO_URL = `${ORDERS_CONTACTS_SERVICE_URL}/manufacturing-orders`;
export const BASE_IO_URL = `${ORDERS_CONTACTS_SERVICE_URL}/internal-operations-orders`;
export const BASE_RETURNS_URL = `${ORDERS_CONTACTS_SERVICE_URL}/return-orders`;
export const BASE_PO_URL = `${ORDERS_CONTACTS_SERVICE_URL}/purchase-orders`;
export const BASE_SO_URL = `${ORDERS_CONTACTS_SERVICE_URL}/sales-orders`;

export const BASE_CONTACTS_URL = `${ORDERS_CONTACTS_SERVICE_URL}/contacts`;
export const BASE_RULES_URL = `${ORDERS_CONTACTS_SERVICE_URL}/rules`;

export const PRODUCT_DAMAGE_CATEGORIES_URL = `${ORDERS_CONTACTS_SERVICE_URL}/product-damage-categories`;
export const CROSS_DOCKING_URL = `${ORDERS_CONTACTS_SERVICE_URL}/cross-dockings`;
export const MO_DAMAGES_URL = `${BASE_MO_URL}/damaged-products`;

export const BASE_SKUS_URL = `${SKU_SERVICE_URL}/skus`;
export const BASE_SKU_CATEGORIES_URL = `${SKU_SERVICE_URL}/sku-categories`;

export const BASE_UOMS_URL = `${SKU_SERVICE_URL}/uoms`;
export const BASE_UOM_CATEGORIES_URL = `${SKU_SERVICE_URL}/uom-categories`;

export const BASE_TASKS_URL = `${TASKS_SERVICE_URL}/tasks`;
export const BASE_TASK_CHAINS_URL = `${TASKS_SERVICE_URL}/task-chains`;
export const BASE_TASK_TEMPLATES_URL = `${TASKS_SERVICE_URL}/task-templates`;

export const BASE_USERS_URL = `${USERS_AND_REPORTING_SERVICE_URL}/users`;
export const BASE_ACTION_LOGS_URL = `${USERS_AND_REPORTING_SERVICE_URL}/action-logs`;
export const BASE_CLIENT_SETTINGS_URL = `${USERS_AND_REPORTING_SERVICE_URL}/client-settings`;

export const BASE_AGGREGATED_COMPLETED_SALES_URL = `${FORECASTING_SERVICE_URL}/completed-sales-order-products`;
export const BASE_AGGREGATED_FORECASTED_SALES_URL = `${FORECASTING_SERVICE_URL}/forecasted-sales-results`;
export const BASE_SKU_SALES_FORECAST_URL = `${FORECASTING_SERVICE_URL}/sku-sales-forecast`;
export const BASE_INVENTORY_BALANCE_URL = `${FORECASTING_SERVICE_URL}/inventory-balance`;
export const BASE_AGGREGATED_PURCHASE_ORDER_PRODUCTS_URL = `${FORECASTING_SERVICE_URL}/purchase-order-products`;
export const BASE_PROCESSED_FORECASTED_SALES_RESULTS = `${FORECASTING_SERVICE_URL}/processed-forecasted-sales-results`;
export const BASE_SKU_SALES_PROMOTIONS_URL = `${FORECASTING_SERVICE_URL}/sku-sales-promotions`;
