export type Updater<T> = (oldState: T) => T;

//This maybe should be in settings folder
export type ColumnSetting = {
  id?: string;
  field: string;
  hidden: boolean;
};

export enum ExportVariant {
  ALL = 'all',
  CURRENT_PAGE = 'current_page',
  SELECTED = 'selected',
  FILTERED = 'filtered',
}

export enum ExportType {
  CSV = 'csv',
  XLSX = 'xlsx',
}
