import React, { createContext, useContext, useState, ReactNode } from 'react';

type SearchButtonContextType = {
  searchButtonDisabled: boolean;
  setSearchButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchButtonContext = createContext<SearchButtonContextType | undefined>(undefined);

export const useSearchButton = (): SearchButtonContextType => {
  const context = useContext(SearchButtonContext);
  if (!context) {
    throw new Error('useSearchButton must be used within a SearchButtonProvider');
  }
  return context;
};

type SearchButtonProviderProps = {
  children: ReactNode;
};

export const SearchButtonProvider = ({ children }: SearchButtonProviderProps): JSX.Element => {
  const [searchButtonDisabled, setSearchButtonDisabled] = useState(false);

  return (
    <SearchButtonContext.Provider value={{ searchButtonDisabled, setSearchButtonDisabled }}>
      {children}
    </SearchButtonContext.Provider>
  );
};
