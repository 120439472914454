import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem('language') || 'lt-LT',
    fallbackLng: 'lt-LT',
    interpolation: {
      escapeValue: false,
    },
    ns: [
      'actionsLog',
      'common',
      'contacts',
      'employees',
      'inventory',
      'locationClasses',
      'locations',
      'pallets',
      'task',
      'uoms',
      'settings',
      'enums',
      'errors',
      'webTasks',
    ],
    defaultNS: 'common',
    keySeparator: '.',
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?v=${process.env.REACT_APP_VERSION}`,
    },
  });

export default i18n;
