import moment from 'moment';
import { AggregationIntervals } from 'redux/forecasting/types';
import { ChartState } from './InventoryForecastChart';

export const dateRangeValues = [
  {
    label: '{{number}} months',
    number: 2,
    value: moment()
      .subtract(2, 'months')
      .format('YYYY-MM-DD'),
  },
  {
    label: '{{number}} months',
    number: 4,
    value: moment()
      .subtract(4, 'months')
      .format('YYYY-MM-DD'),
    isDefault: true,
  },
  {
    label: '{{number}} months',
    number: 6,
    value: moment()
      .subtract(6, 'months')
      .format('YYYY-MM-DD'),
  },
  {
    label: '{{number}} years',
    number: 1,
    value: moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD'),
  },
  {
    label: '{{number}} years',
    number: 2,
    value: moment()
      .subtract(2, 'years')
      .format('YYYY-MM-DD'),
  },
];

export const getXAxisFormatByAggregationInterval = (interval?: AggregationIntervals) => {
  switch (interval) {
    case AggregationIntervals.Day:
      return moment().format('YYYY-MM-DD');
    case AggregationIntervals.Week:
      return moment().format('YYYY w');
    case AggregationIntervals.Month:
      return moment().format('YYYY-M');
    default:
      return null;
  }
};

export const isLastLabel = (current: string, chartState: ChartState) => {
  const data = chartState?.data;
  const lastLabel = data[data.length - 1]?.label;
  return current === lastLabel;
};

export const isFirstLabel = (current: string, chartState: ChartState) => {
  const data = chartState?.data;
  const firstLabel = data[0]?.label;
  return current === firstLabel;
};
