import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderSettingsJSON } from './types';

type InitialChainIdState = string | null;
type InitialActiveChainState = OrderSettingsJSON | null;

const chainIdState = null as InitialChainIdState;
const activeChainState = null as InitialActiveChainState;

const activeChainId = createSlice({
  name: 'activeChainId',
  initialState: chainIdState,
  reducers: {
    setActiveChainId: (_state, { payload }: PayloadAction<string | null>) => payload,
  },
});

const activeChain = createSlice({
  name: 'activeChain',
  initialState: activeChainState,
  reducers: {
    setActiveChain: (_state, { payload }: PayloadAction<OrderSettingsJSON | null>) => payload,
    addTaskTemplateToActiveChain: (state, { payload }) => {
      if (!state) {
        return null;
      }
      return {
        ...state,
        task_templates: [...state.task_templates, payload],
      };
    },
    setTaskTemplatesInActiveChain: (state, { payload }) => {
      if (!state) {
        return null;
      }
      return {
        ...state,
        task_templates: payload,
      };
    },
  },
});

export const { setActiveChainId } = activeChainId.actions;
export const { setActiveChain, addTaskTemplateToActiveChain, setTaskTemplatesInActiveChain } = activeChain.actions;

export default combineReducers({
  activeChainId: activeChainId.reducer,
  activeChain: activeChain.reducer,
});
