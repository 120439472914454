import React from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CastIcon from '@mui/icons-material/Cast';
import CategoryIcon from '@mui/icons-material/Category';
import ClassIcon from '@mui/icons-material/Class';
import DescriptionIcon from '@mui/icons-material/Description';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ExploreIcon from '@mui/icons-material/Explore';
import ProductsIcon from '@mui/icons-material/FormatAlignJustify';
import GamesIcon from '@mui/icons-material/Games';
import ManufacturingOrdersIcon from '@mui/icons-material/Gavel';
import Home from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ListAlt from '@mui/icons-material/ListAlt';
import MapIcon from '@mui/icons-material/Map';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import PeopleIcon from '@mui/icons-material/People';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ReportsIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
// import TimelineIcon from '@mui/icons-material/Timeline';
import PurchaseOrdersIcon from '@mui/icons-material/ShoppingBasket';
import CatalogueIcon from '@mui/icons-material/ShoppingCart';
import SaleOrdersIcon from '@mui/icons-material/ShopTwo';
import CreateNewFolder from '@mui/icons-material/CreateNewFolder';
import LocalOffer from '@mui/icons-material/LocalOffer';
import AddchartIcon from '@mui/icons-material/Addchart';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsightsIcon from '@mui/icons-material/Insights';
import TableChartIcon from '@mui/icons-material/TableChart';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import { RoutePaths } from 'constants/routePaths';

export default [
  {
    title: 'common:sideBarPages.main',
    id: 'dashboard',
    href: RoutePaths.Dashboard,
    icon: <Home />,
  },
  {
    title: 'common:pageTitles.Inventory forecast',
    id: 'inventory-forecast',
    icon: <InsertChartIcon />,
    items: [
      {
        title: 'common:sideBarPages.PurchasePanel',
        id: 'purchase-dashboard',
        href: RoutePaths.PurchaseDashboard,
        icon: <PurchaseOrdersIcon />,
      },
      {
        title: 'common:pageTitles.Forecasts',
        id: 'inventory-forecast-forecasts',
        href: RoutePaths.InventoryForecasts,
        icon: <InsightsIcon />,
      },
      {
        title: 'common:pageTitles.Replenishments',
        id: 'inventory-forecast-replenishments',
        href: RoutePaths.InventoryReplenishments,
        icon: <AddchartIcon />,
      },
      {
        title: 'common:pageTitles.Purchasing analytics',
        id: 'inventory-forecast-analytics',
        href: RoutePaths.InventoryAnalytics,
        icon: <TableChartIcon />,
      },
      {
        title: 'common:sideBarPages.promotions',
        id: 'promotions',
        href: RoutePaths.Promotions,
        icon: <LocalOffer />,
      },
      {
        title: 'common:sideBarPages.missingData',
        id: 'replenishments-validation',
        href: RoutePaths.ReplenishmentsValidation,
        icon: <Home />,
      },
    ],
  },
  // {
  //   title: 'common:sideBarPages.forecast',
  //   id: 'forecast',
  //   href: '/forecast',
  //   icon: <TimelineIcon />,
  // },
  {
    title: 'common:sideBarPages.warehouseManagement',
    id: 'warehouse-group',
    icon: <MapIcon />,
    items: [
      {
        title: 'common:sideBarPages.warehouses',
        id: 'warehouses',
        href: RoutePaths.Warehouses,
        icon: <WarehouseIcon />,
      },
      {
        title: 'common:sideBarPages.locationsMap',
        id: 'locations',
        href: RoutePaths.Locations,
        icon: <ExploreIcon />,
      },
      {
        title: 'common:sideBarPages.inventory',
        id: 'inventory',
        href: RoutePaths.Inventory,
        icon: <ProductsIcon />,
      },
      {
        title: 'common:sideBarPages.inventoryConsumables',
        id: 'inventory-consumables',
        href: RoutePaths.InventoryConsumables,
        icon: <LabelImportantIcon />,
      },
      {
        title: 'common:sideBarPages.pallets',
        id: 'pallets',
        href: RoutePaths.Pallets,
        icon: <GamesIcon />,
      },
      {
        title: 'common:sideBarPages.locationClasses',
        id: 'location-classes',
        href: RoutePaths.LocationClasses,
        icon: <LibraryBooksIcon />,
      },
    ],
  },
  {
    title: 'common:sideBarPages.ordersManagement',
    id: 'orders-group',
    icon: <DescriptionIcon />,
    items: [
      {
        title: 'common:sideBarPages.purchaseOrders',
        id: 'purchase-orders',
        href: RoutePaths.PurchaseOrders,
        icon: <PurchaseOrdersIcon />,
      },
      {
        title: 'common:sideBarPages.salesOrders',
        id: 'sales-orders',
        href: RoutePaths.SalesOrders,
        icon: <SaleOrdersIcon />,
      },
      {
        title: 'common:sideBarPages.internalTransfers',
        id: 'internal-operations',
        href: RoutePaths.InternalOperations,
        icon: <ImportExportIcon />,
      },
      {
        title: 'common:sideBarPages.returnOrders',
        id: 'returns',
        href: RoutePaths.Returns,
        icon: <KeyboardReturnIcon />,
      },
    ],
  },
  {
    title: 'common:sideBarPages.manufacturing',
    id: 'mo-group',
    icon: <ManufacturingOrdersIcon />,
    items: [
      {
        title: 'common:sideBarPages.manufacturingTasks',
        id: 'manufacturing-orders',
        href: RoutePaths.ManufacturingOrders,
        icon: <HomeWorkIcon />,
      },
      {
        title: 'common:sideBarPages.manufacturingOrdersDisassemble',
        id: 'manufacturing-orders-disassemble',
        href: RoutePaths.ManufacturingOrdersDisassemble,
        icon: <HorizontalSplitIcon />,
      },
    ],
  },
  {
    title: 'common:sideBarPages.products',
    id: 'sku-group',
    icon: <ClassIcon />,
    items: [
      {
        title: 'common:sideBarPages.catalogue',
        id: 'catalogue',
        href: RoutePaths.SkuCatalogue,
        icon: <CatalogueIcon />,
      },
      {
        title: 'common:sideBarPages.productCategories',
        id: 'sku-categories',
        href: RoutePaths.SkuCategories,
        icon: <CategoryIcon />,
      },
      {
        title: 'common:sideBarPages.uoms',
        id: 'uoms',
        href: RoutePaths.Uoms,
        icon: <EqualizerIcon />,
      },
      {
        title: 'common:sideBarPages.uomCategories',
        id: 'uom-categories',
        href: RoutePaths.UomCategories,
        icon: <AccountTreeIcon />,
      },
    ],
  },
  {
    title: 'common:sideBarPages.damagesReports',
    id: 'damages-reports',
    href: '/damages-reports',
    icon: <ReportsIcon />,
  },
  {
    title: 'common:sideBarPages.reports',
    id: 'reports',
    href: RoutePaths.Reports,
    icon: <CreateNewFolder />,
  },
  {
    title: 'common:sideBarPages.contacts',
    id: 'contacts',
    href: RoutePaths.Contacts,
    icon: <PermContactCalendarIcon />,
  },
  {
    title: 'common:sideBarPages.webTasks',
    id: 'web-tasks',
    icon: <FormatListBulletedIcon />,
    items: [
      {
        title: 'common:sideBarPages.receivingTasks',
        id: 'receiving-tasks',
        icon: <AssignmentTurnedInIcon />,
        href: RoutePaths.ReceivingTasks,
      },
      {
        title: 'common:sideBarPages.putawayTasks',
        id: 'putaway-tasks',
        icon: <AssignmentTurnedInIcon />,
        href: RoutePaths.PutawayTasks,
      },
      {
        title: 'common:sideBarPages.inProgressTasks',
        id: 'in-progress-tasks',
        icon: <WorkHistoryIcon />,
        href: RoutePaths.InProgressTasks,
      },
    ],
  },
  {
    title: 'common:sideBarPages.tasks',
    id: 'tasks',
    href: RoutePaths.Tasks,
    icon: <ListAlt />,
  },
  {
    title: 'common:sideBarPages.employees',
    id: 'employees',
    href: RoutePaths.Employees,
    icon: <PeopleIcon />,
  },
  {
    title: 'common:sideBarPages.actionslog',
    id: 'action-logs',
    href: RoutePaths.ActionLogs,
    icon: <MenuBookIcon />,
  },
  {
    title: 'common:sideBarPages.requestResponseLog',
    id: 'request-response-log',
    href: RoutePaths.RequestResponseLog,
    icon: <CastIcon />,
  },
  {
    title: 'common:sideBarPages.settings',
    id: 'settings',
    href: RoutePaths.Settings,
    icon: <SettingsIcon />,
  },
];
