import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocationClassResponse } from './types';

type InitialActiveIdState = null | string;
const initialActiveIdState = null as InitialActiveIdState;
const activeId = createSlice({
  name: 'activeId',
  initialState: initialActiveIdState,
  reducers: {
    setActiveLocationClassId: (_state, { payload }: PayloadAction<InitialActiveIdState>) => payload,
  },
});

type InitialActiveLocationClassState = null | LocationClassResponse;
const initialActiveLocationState = null as InitialActiveLocationClassState;
const active = createSlice({
  name: 'active',
  initialState: initialActiveLocationState,
  reducers: {
    setActiveLocationClass: (_state, { payload }: PayloadAction<InitialActiveLocationClassState>) =>
      payload,
  },
});

export const { setActiveLocationClassId } = activeId.actions;
export const { setActiveLocationClass } = active.actions;

export default combineReducers({
  active: active.reducer,
  activeId: activeId.reducer,
});
