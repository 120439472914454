import { State } from 'redux/store';
import { connect } from 'react-redux';
import Component from './Component';

const mapStateToProps = (state: State) => ({
  isVisible: state.snackbar.isVisible,
  message: state.snackbar.message,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  severity: state.snackbar.severity,
});

export default connect(mapStateToProps)(Component);
