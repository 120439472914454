import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LocalSettings, SettingsJSON } from 'redux/settings/types';

const initialState: SettingsJSON = {
  isSVSModuleEnabled: false,
  isForecastingModuleEnabled: false,
  isChangePriceButtonDisabled: false,
  isLocationExportDisabled: false,
  tables: {},
  forms: {},
  disabledRoutes: [],
  sidebarPagesTitles: [],
  // TODO: automated po
  isAutomatedPOEnabled: false,
  isManualPOEnabled: false,
  isUsingOldIOForm: false,
};

const localSettingsState: LocalSettings = {
  svsModuleChecked: false,
  forecastingModuleChecked: false,
  // TODO: automated po
  isAutomatedPOEnabled: false,
  isManualPOEnabled: false,
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (_: any, { payload }: PayloadAction<SettingsJSON>) => payload,
  },
});
const localSettings = createSlice({
  name: 'localSettings',
  initialState: localSettingsState,
  reducers: {
    setLocalSettings: (_: any, { payload }: PayloadAction<LocalSettings>) => payload,
  },
});

export const { setSettings } = settings.actions;
export const { setLocalSettings } = localSettings.actions;

export default combineReducers({
  settings: settings.reducer,
  localSettings: localSettings.reducer,
});
