import { State } from 'redux/store';
import { hideColumns } from 'components/mrtTable/utils';
import pages from 'layouts/Main/components/Sidebar/pages';
import { MRT_ColumnDef } from 'material-react-table';
import { createSelector } from 'reselect';
import { merge } from 'utils/merge';
import { selectUserGroupName } from 'redux/user/userSlice';

export const selectColumns = createSelector(
  (state: any, { key }: { key: string }) => ({
    columns: state?.settings?.settings?.tables[key]?.columns,
    key,
    localSettings: state.settings.localSettings,
  }),
  (_: any, { columns }: { columns: any }) => columns,
  (table: any, columns: any) => merge(columns, table.columns, table.key, table.localSettings)
);

// Table columns visibility selector for MRT table
export const selectVisibleColumns = createSelector(
  (state: State, { key }: { key: string }) => ({
    columns: state?.settings?.settings?.tables?.[key]?.columns,
    key,
    localSettings: state.settings.localSettings,
  }),
  (_: any, { columns }: { columns: MRT_ColumnDef<any>[] }) => columns,
  (table: any, columns) => hideColumns(columns, table.columns)
);

export const selectTableVisibility = (state: any, key: string) => {
  const table = state.settings.settings.tables[key];
  if (!table || table.hidden === undefined) {
    return true;
  }
  return !table.hidden;
};

export const selectInvisibleFormFields = (state: any, key: string) => {
  const form = state.settings.settings.forms && state.settings.settings.forms[key];
  if (!form) {
    return {};
  }
  return form.invisibleFields;
};

export const selectIsReviewingActionEnabled = (state: State, key: string) =>
  state.settings.settings.forms?.[key]?.reviewingActionEnabled ?? false;

export const selectSidebarPages = (state: State) => {
  const { sidebarPagesTitles } = state.settings.settings;
  if (!sidebarPagesTitles || !sidebarPagesTitles.length) {
    return pages;
  }
  const normalizedRoutes = pages.map(page => {
    const matchedRoute = sidebarPagesTitles.find(routeTitle => routeTitle.id === page.id);
    if (matchedRoute) {
      return {
        ...page,
        ...matchedRoute,
      };
    }
    return page;
  });
  return normalizedRoutes;
};

const DISABLED_ROUTES_FOR_MANAGER = ['employees', 'orders-settings'];
const IS_SVS_DISABLED_ROUTES = [
  'pallets',
  'locations',
  'location-classes',
  // TODO: demo changes
  // 'internal-operations',
  'returns',
  'mo-group',
  'dashboard',
  'manufacturing-orders',
  'inventory-consumables',
  'tasks',
  'action-logs',
  'request-response-log',
];
const IS_FORECASTING_DISABLED_ROUTES = [
  'forecast',
  'promotions',
  'replenishments-validation',
  'purchase-dashboard',
  'inventory-forecast-replenishments',
  'inventory-forecast-analytics',
  'inventory-forecast-forecasts',
  'inventory-forecast',
];
const RETAIL_MANAGER_DISABLED_ROUTES = [
  ...IS_FORECASTING_DISABLED_ROUTES,
  'settings',
  'request-response-log',
  'action-logs',
  'employees',
  'tasks',
  'reports',
  'sku-group',
  'catalogue',
  'sku-categories',
  'uoms',
  'uom-categories',
  'mo-group',
  'manufacturing-orders',
  'purchase-orders',
  'inventory-consumables',
  'pallets',
  'locations',
  'inventory-consumables',
  'location-classes',
  'warehouses',
];

export const selectDisabledRoutes = (state: State) => {
  const userGroup = selectUserGroupName(state);
  const { settings, localSettings } = state.settings;
  const settingsDisabledRoutes = settings?.disabledRoutes || [];
  const isSVSModuleEnabled = settings?.isSVSModuleEnabled || false;
  const isForecastingModuleEnabled = settings?.isForecastingModuleEnabled || false;

  let disabledRoutes = [...settingsDisabledRoutes];

  if (!isSVSModuleEnabled || !localSettings.svsModuleChecked || userGroup === 'product_manager') {
    disabledRoutes = [...disabledRoutes, ...IS_SVS_DISABLED_ROUTES];
  }

  if (!isForecastingModuleEnabled || !localSettings.forecastingModuleChecked) {
    disabledRoutes = [...disabledRoutes, ...IS_FORECASTING_DISABLED_ROUTES];
  }

  if (userGroup === 'product_manager') {
    disabledRoutes = [...disabledRoutes, ...DISABLED_ROUTES_FOR_MANAGER];
  } else if (userGroup === 'retail_manager') {
    disabledRoutes = [...disabledRoutes, ...RETAIL_MANAGER_DISABLED_ROUTES];
  }

  return disabledRoutes;
};

export const selectSettings = (state: State) => state.settings.settings;
export const selectLocalSettings = (state: State) => state.settings.localSettings;
