import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UomCategoryResponse, UomResponse } from './apiTypes';
import { Uom } from './types';

/*------------------------------ UOMS -------------------------------------*/
type InitialUomIdState = string | null;
type InitialActiveUomState = UomResponse | null;

const uomIdState = null as InitialUomIdState;
const activeUomState = null as InitialActiveUomState;

const activeUomId = createSlice({
  name: 'activeUomId',
  initialState: uomIdState,
  reducers: {
    setActiveUomId: (_state, { payload }: PayloadAction<string | null>) => payload,
  },
});

const activeUom = createSlice({
  name: 'activeUom',
  initialState: activeUomState,
  reducers: {
    setActiveUom: (_state, { payload }: PayloadAction<UomResponse | null>) => payload,
  },
});

type UomsInitialState = Uom[] | null;
const uomsInitialState = null as UomsInitialState;

const uoms = createSlice({
  name: 'uoms',
  initialState: uomsInitialState,
  reducers: {
    setUoms: (_state, { payload }: PayloadAction<Uom[] | null>) => payload,
  },
});

export const { setActiveUomId } = activeUomId.actions;
export const { setActiveUom } = activeUom.actions;
export const { setUoms } = uoms.actions;

/*------------------------------ UOM CATEGORIES-------------------------------------*/
type InitialUomCategoryIdState = string | null;
type InitialActiveUomCategoryState = UomCategoryResponse | null;

const uomCategoryIdState = null as InitialUomCategoryIdState;
const activeUomCategoryState = null as InitialActiveUomCategoryState;

const activeUomCategoryId = createSlice({
  name: 'activeUomCategoryId',
  initialState: uomCategoryIdState,
  reducers: {
    setActiveUomCategoryId: (_state, { payload }: PayloadAction<string | null>) => payload,
  },
});

const activeUomCategory = createSlice({
  name: 'activeUomCategory',
  initialState: activeUomCategoryState,
  reducers: {
    setActiveUomCategory: (_state, { payload }: PayloadAction<UomCategoryResponse | null>) => payload,
  },
});

export const { setActiveUomCategoryId } = activeUomCategoryId.actions;
export const { setActiveUomCategory } = activeUomCategory.actions;

export default combineReducers({
  activeUomId: activeUomId.reducer,
  activeUom: activeUom.reducer,
  activeUomCategoryId: activeUomCategoryId.reducer,
  activeUomCategory: activeUomCategory.reducer,
});
