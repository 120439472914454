import { State } from 'redux/store';
import { history } from 'redux/history';
import { KBarProvider } from 'kbar';
import { SidebarPage } from 'layouts/Main/components/Sidebar/types';
import React, { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectDisabledRoutes, selectSidebarPages } from 'redux/settings/selectors';
import { useSearchButton } from './SearchButtonContext';
import { GAEventAction, GAEventCategory, trackCustomGAEvent } from 'utils/googleAnalytics';
import keywords from './keywords';

const AppKBarProvider: React.FC<PropsWithChildren<{ children?: React.ReactNode[] }>> = ({ children }) => {
  const { setSearchButtonDisabled } = useSearchButton();
  const { t, i18n } = useTranslation();
  const sidebarPages: SidebarPage[] = useSelector((state: State) => selectSidebarPages(state));
  const disabledRoutes = useSelector((state: State) => selectDisabledRoutes(state));

  const mapKeywordsToActionsByLocale = (pageId: string, itemTitle: string) => {
    const currentLanguage = i18n.language;
    const keywordObject = (keywords.find(item => item.id === pageId) as unknown) as { [key: string]: string[] };
    if (keywordObject && keywordObject[currentLanguage]) {
      return keywordObject[currentLanguage].map(keyword => t(keyword));
    }
    return [itemTitle];
  };

  const actions = useMemo(() => {
    return sidebarPages.flatMap(page => {
      const isPageDisabled = disabledRoutes.includes(page.id);
      const pageActions =
        page.href && !isPageDisabled
          ? [
              {
                id: page.id,
                name: t(page.title),
                shortcut: [],
                keywords: (mapKeywordsToActionsByLocale(page.id, t(page.title)) as unknown) as string,
                icon: page.icon,
                perform: () => {
                  // category: 'App search bar', action: 'Select search result', label: selected item title
                  trackCustomGAEvent(GAEventCategory.SEARCH_BAR, GAEventAction.SELECT_RESULT, t(page.title));
                  history.push(page.href);
                },
              },
            ]
          : [];

      if (page.items) {
        const itemActions = page.items.flatMap(item => {
          const isItemDisabled = disabledRoutes.includes(item.id);

          return item.href && !isItemDisabled
            ? [
                {
                  id: item.id,
                  name: t(item.title),
                  shortcut: [],
                  keywords: (mapKeywordsToActionsByLocale(
                    item.id,
                    `${t(page.title)} ${t(item.title)}`
                  ) as unknown) as string,
                  icon: item.icon,
                  section: t(page.title),
                  perform: () => {
                    trackCustomGAEvent(GAEventCategory.SEARCH_BAR, GAEventAction.SELECT_RESULT, t(item.title));
                    history.push(item.href);
                  },
                  ...(page.id === 'orders-group' && {
                    priority: 2,
                  }),
                  ...(page.id === 'warehouse-group' && {
                    priority: 1,
                  }),
                },
              ]
            : [];
        });

        return [...pageActions, ...itemActions];
      }

      return pageActions;
    });
  }, [sidebarPages, disabledRoutes, t, i18n.language]);

  return (
    <KBarProvider
      actions={actions}
      options={{
        disableScrollbarManagement: true,
        callbacks: {
          onClose: () => setSearchButtonDisabled(false),
          onSelectAction: () => setSearchButtonDisabled(false),
        },
      }}
    >
      {children}
    </KBarProvider>
  );
};

export default AppKBarProvider;
