export default [
  {
    id: 'dashboard',
    keywords: ['home', 'main', 'main page', 'main-page', 'dashboard', 'pagrindinis', 'pagrindinis puslapis'],
    ['en-GB']: ['home', 'main', 'main page', 'main-page', 'dashboard'],
    ['lt-LT']: ['pagrindinis', 'pagrindinis puslapis', 'skydelis', 'skidelis'],
  },
  {
    id: 'replenishments-validation',
    keywords: ['validation', 'trūksama'],
    ['en-GB']: ['validation', 'missing', 'data'],
    ['lt-LT']: ['trūksama', 'trukstama', 'informacija'],
  },
  {
    id: 'forecast',
    keywords: [
      'forecast',
      'analytics',
      'analysis',
      'analitics',
      'prognoze',
      'prognozė',
      'prognozes',
      'prognozės',
      'analitika',
      'analize',
      'analizė',
      'duomenys',
      'ataskaita',
      'duomenis',
    ],
    ['en-GB']: ['forecast', 'analytics', 'analysis', 'analitics'],
    ['lt-LT']: [
      'prognoze',
      'prognozė',
      'prognozes',
      'prognozės',
      'analitika',
      'analize',
      'analizė',
      'duomenys',
      'duomenis',
      'ataskaita',
    ],
  },
  {
    id: 'promotions',
    keywords: ['nuolaidos', 'akcijos', 'promotions', 'discounts', 'akcija', 'nuolaida', 'pasiūlymai', 'pasiulymai'],
    ['en-GB']: ['promotions', 'discounts'],
    ['lt-LT']: ['nuolaidos', 'akcijos', 'akcija', 'nuolaida', 'pasiūlymai', 'pasiulymai'],
  },
  {
    id: 'purchase-dashboard',
    keywords: ['purchase', 'pannel', 'skydelis', 'panele'],
    ['en-GB']: ['purchase-dashboard'],
    ['lt-LT']: ['skydelis', 'panele'],
  },
  {
    id: 'warehouse-group',
  },
  {
    id: 'locations',
    keywords: ['locations', 'location', 'locat', 'sandelio vietos', 'sandėlio vietos', 'vietos'],
    ['en-GB']: ['locations', 'location', 'locat', 'warehouse locations'],
    ['lt-LT']: ['sandelio vietos', 'sandėlio vietos', 'vietos'],
  },
  {
    id: 'inventory',
    keywords: [
      'inventory',
      'inventorius',
      'inventoriaus',
      'inventorija',
      'inventoriumas',
      'inventoriumo',
      'iranga',
      'įranga',
    ],
    ['en-GB']: ['inventory'],
    ['lt-LT']: ['inventorius', 'inventoriaus', 'inventorija', 'inventoriumas', 'inventoriumo', 'iranga', 'įranga'],
  },
  {
    id: 'pallets',
    keywords: ['pallets', 'pallet', 'palete', 'paletes', 'paletė', 'paletės'],
    ['en-GB']: ['pallets', 'pallet', 'palette', 'palettes', 'palete', 'paletes'],
    ['lt-LT']: ['palete', 'paletes', 'paletė', 'paletės'],
  },
  {
    id: 'location-classes',
    keywords: ['location classes', 'location class', 'location', 'class', 'klases', 'klasės', 'klase', 'klasė'],
    ['en-GB']: ['location classes', 'location class', 'location', 'class'],
    ['lt-LT']: ['klases', 'klasės', 'klase', 'klasė'],
  },
  {
    id: 'orders-group',
  },
  {
    id: 'purchase-orders',
    keywords: [
      'purchase orders',
      'purchase order',
      'purchase',
      'orders',
      'order',
      'purchases',
      'užsakymai',
      'uzsakymai',
      'uzsakimai',
      'užsakimai',
      'pirkimas',
      'pirkimai',
      'pirk',
      'nupirk',
      'pirkiniai',
      'įsigyti',
      'isigi',
      'isigy',
    ],
    ['en-GB']: ['purchase orders', 'purchase order', 'purchase', 'orders', 'order', 'purchases'],
    ['lt-LT']: [
      'užsakymai',
      'uzsakymai',
      'uzsakimai',
      'užsakimai',
      'pirkimas',
      'pirkimai',
      'pirk',
      'nupirk',
      'pirkiniai',
      'įsigyti',
      'isigi',
      'isigy',
    ],
  },
  {
    id: 'sales-orders',
    keywords: [
      'sales orders',
      'sales order',
      'sales',
      'orders',
      'order',
      'pardavimai',
      'pardavimas',
      'pardavimo',
      'pardavimų',
      'pardavimo užsakymai',
      'pardavimo užsakymas',
      'uzsakymai',
      'prekyba',
      'pardave',
      'pardavė',
    ],
    ['en-GB']: ['sales orders', 'sales order', 'sales', 'orders', 'order'],
    ['lt-LT']: [
      'pardavimai',
      'pardavimas',
      'pardavimo',
      'pardavimų',
      'pardavimo užsakymai',
      'pardavimo užsakymas',
      'uzsakymai',
      'prekyba',
      'pardave',
      'pardavė',
    ],
  },
  {
    id: 'internal-operations',
    keywords: [
      'internal operations',
      'internal operation',
      'internal',
      'operations',
      'operation',
      'vidiniai veiksmai',
      'vidinis veiksmas',
      'vidinis',
      'vidaus',
      'veiksmas',
      'perkelimas',
      'perkelimai',
      'perkėlimas',
      'perkėlimai',
      'kelimai',
      'nukelimai',
      'iskelimai',
      'iškelimai',
      'iškėlimai',
      'iškelimai',
      'uzsakymai',
      'užsakymai',
    ],
    ['en-GB']: ['internal operations', 'internal operation', 'internal', 'operations', 'operation'],
    ['lt-LT']: [
      'vidiniai veiksmai',
      'vidinis veiksmas',
      'vidinis',
      'vidaus',
      'veiksmas',
      'perkelimas',
      'perkelimai',
      'perkėlimas',
      'perkėlimai',
      'kelimai',
      'nukelimai',
      'iskelimai',
      'iškėlimai',
      'iškelimai',
      'uzsakymai',
      'užsakymai',
    ],
  },
  {
    id: 'returns',
    keywords: [
      'returns',
      'return',
      'grazinimai',
      'grazinimas',
      'grazinimo',
      'grąžinimai',
      'grąžinimas',
      'grąžinimo',
      'pargrazinimai',
      'uzsakymai',
      'užsakymai',
    ],
    ['en-GB']: ['returns', 'return'],
    ['lt-LT']: [
      'grazinimai',
      'grazinimas',
      'grazinimo',
      'grąžinimai',
      'grąžinimas',
      'grąžinimo',
      'pargrazinimai',
      'uzsakymai',
      'užsakymai',
    ],
  },
  {
    id: 'mo-group',
  },
  {
    id: 'manufacturing-orders',
    keywords: [
      'manufacturing',
      'order',
      'orders',
      'gamyba',
      'gaminimas',
      'gaminiai',
      'gaminys',
      'uzsakymai',
      'užsakymai',
    ],
    ['en-GB']: ['manufacturing', 'order', 'orders'],
    ['lt-LT']: ['gamyba', 'gaminimas', 'gaminiai', 'gaminys', 'uzsakymai', 'užsakymai'],
  },
  {
    id: 'sku-group',
  },
  {
    id: 'catalogue',
    keywords: [
      'produktas',
      'produkto',
      'prekiu',
      'prekių',
      'katalogas',
      'prekes',
      'prekės',
      'preke',
      'prekė',
      'prekes informacija',
      'prekės informacija',
      'koretele',
      'koretelė',
      'detal',
      'product',
      'products',
      'catalogue',
      'catalog',
      'sku',
      'skus',
      'sku information',
      'sku info',
    ],
    ['en-GB']: ['product', 'products', 'catalogue', 'catalog', 'sku', 'skus', 'sku information', 'sku info'],
    ['lt-LT']: [
      'produktas',
      'produkto',
      'prekiu',
      'prekių',
      'katalogas',
      'prekes',
      'prekės',
      'preke',
      'prekė',
      'prekes informacija',
      'prekės informacija',
      'koretele',
      'koretelė',
      'detal',
    ],
  },
  {
    id: 'sku-categories',
    keywords: [
      'sku categories',
      'sku category',
      'sku',
      'type',
      'categories',
      'category',
      'kategorijos',
      'kategorija',
      'skirstymas',
      'klasifikacija',
      'tipai',
      'tipas',
      'preki',
      'produkt',
    ],
    ['en-GB']: ['sku categories', 'sku category', 'sku', 'categories', 'category', 'type'],
    ['lt-LT']: ['kategorijos', 'kategorija', 'skirstymas', 'klasifikacija', 'tipai', 'tipas', 'prekių', 'produkt'],
  },
  {
    id: 'uoms',
    keywords: [
      'uoms',
      'uom',
      'metrics',
      'units',
      'measurement',
      'matavimas',
      'mataviami',
      'matavimo',
      'vienetai',
      'vienetas',
      'vienetų',
      'matas',
      'mato',
      'vnt',
    ],
    ['en-GB']: ['uoms', 'uom', 'metrics', 'units', 'measurement'],
    ['lt-LT']: ['matavimas', 'mataviami', 'matavimo', 'vienetai', 'vienetas', 'vienetų', 'matas', 'mato', 'vnt'],
  },
  {
    id: 'uom-categories',
    keywords: [
      'categories',
      'category',
      'uom',
      'units',
      'type',
      'measurement',
      'matavimo',
      'kategorijos',
      'klasifikacija',
      'tipai',
      'tipas',
      'matas',
      'mato',
      'vnt',
    ],
    ['en-GB']: ['categories', 'category', 'uom', 'uoms', 'type', 'metrics', 'units', 'measurement'],
    ['lt-LT']: ['matavimo', 'kategorijos', 'klasifikacija', 'tipai', 'tipas', 'matas', 'mato', 'vnt'],
  },
  {
    id: 'reports',
    kewords: [
      'reports',
      'report',
      'results',
      'ataskaitos',
      'ataskaita',
      'pranesimas',
      'pranešimas',
      'pranesimai',
      'pranešimai',
      'rezultatai',
    ],
    ['en-GB']: ['reports', 'report', 'results'],
    ['lt-LT']: ['ataskaitos', 'ataskaita', 'pranesimas', 'pranešimas', 'pranesimai', 'pranešimai', 'rezultatai'],
  },
  {
    id: 'contacts',
    keywords: [
      'contacts',
      'contact',
      'email',
      'phone',
      'address',
      'kontaktai',
      'kontaktas',
      'kontaktų',
      'kontaktinis',
      'telefonas',
      'telefonai',
      'el.',
      'pastas',
      'paštas',
      'adresas',
    ],
    ['en-GB']: ['contacts', 'contact', 'email', 'phone', 'address'],
    ['lt-LT']: [
      'kontaktai',
      'kontaktas',
      'kontaktų',
      'kontaktinis',
      'telefonas',
      'telefonai',
      'el.',
      'pastas',
      'paštas',
      'adresas',
    ],
  },
  {
    id: 'tasks',
    keywords: [
      'tasks',
      'task',
      'job',
      'jobs',
      'uzduotys',
      'uzduotis',
      'uzduoties',
      'uzduotys',
      'uzduoti',
      'uzduotį',
      'uzduotis',
      'užduotis',
      'užduotys',
    ],
    ['en-GB']: ['tasks', 'task', 'job', 'jobs'],
    ['lt-LT']: [
      'uzduotys',
      'uzduotis',
      'uzduoties',
      'uzduotys',
      'uzduoti',
      'uzduotį',
      'uzduotis',
      'užduotis',
      'užduotys',
    ],
  },
  {
    id: 'employees',
    keywords: [
      'employees',
      'employee',
      'workers',
      'worker',
      'team',
      'staff',
      'colleagues',
      'darbuotojai',
      'darbuotojas',
      'darbininkai',
      'darbininkas',
      'komanda',
      'personalas',
      'kolegos',
    ],
    ['en-GB']: ['employees', 'employee', 'workers', 'worker', 'team', 'staff', 'colleagues'],
    ['lt-LT']: ['darbuotojai', 'darbuotojas', 'darbininkai', 'darbininkas', 'komanda', 'personalas', 'kolegos'],
  },
  {
    id: 'action-logs',
    keywords: [
      'action',
      'actions',
      'history',
      'histories',
      'log',
      'logs',
      'veiksmų',
      'veiksmas',
      'istorija',
      'istorijos',
      'logas',
      'logai',
    ],
    ['en-GB']: ['action', 'actions', 'history', 'histories', 'log', 'logs'],
    ['lt-LT']: ['veiksmų', 'veiksmas', 'istorija', 'istorijos', 'logas', 'logai'],
  },
  {
    id: 'request-response-log',
    keywords: [
      'query',
      'queries',
      'request',
      'response',
      'third party',
      'tsm',
      'programines',
      'programinės',
      'uzklausos',
      'užklausos',
      'logas',
      'tsm',
    ],
    ['en-GB']: ['query', 'queries', 'request', 'response', 'third party', 'tsm'],
    ['lt-LT']: ['programines', 'programinės', 'uzklausos', 'užklausos', 'logas', 'tsm'],
  },
  {
    id: 'settings',
    keywords: [
      'settings',
      'setting',
      'configuration',
      'configurations',
      'options',
      'option',
      'system settings',
      'nustatymai',
      'nustatymų',
      'nustatymas',
      'konfigūracija',
      'konfiguracija',
      'parinktys',
      'sistemos nustatymai',
      'parinktis',
    ],
    ['en-GB']: ['settings', 'setting', 'configuration', 'configurations', 'options', 'option', 'system settings'],
    ['lt-LT']: [
      'parinktis',
      'parinktys',
      'konfigūracija',
      'konfiguracija',
      'sistemos nustatymai',
      'nustatymai',
      'nustatymų',
      'nustatymas',
    ],
  },
];
