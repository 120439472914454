import { combineReducers, createSlice } from '@reduxjs/toolkit';

const apiErrors = createSlice({
  name: 'apiErrors',
  initialState: null,
  reducers: {
    setApiErrors: (_state, { payload }) => payload,
  },
});

export const { setApiErrors } = apiErrors.actions;

export default combineReducers({
  apiErrors: apiErrors.reducer,
});
