import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  backRoute: undefined, // Set to undefined initially
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setBackRoute: (state, action) => {
      state.backRoute = action.payload;
    },
    resetBackRoute: state => {
      state.backRoute = undefined; // Reset to undefined
    },
  },
});

export const { setBackRoute, resetBackRoute } = navigationSlice.actions;

export default navigationSlice.reducer;
