export class AjaxError<T = string> extends Error {
  responseJSON?: T;
  status?: number;
  responseText?: string;
  errorThrown?: string;
  constructor(
    message?: string,
    response?: { responseJSON?: T; status?: number; responseText?: string },
    errorThrown?: string,
    missingStack?: string
  ) {
    super(message); // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = '';
    this.responseJSON = response ? response.responseJSON : undefined;
    this.status = response ? response.status : undefined;
    this.responseText = response ? response.responseText : undefined;
    this.errorThrown = errorThrown;
    if (missingStack) {
      this.stack = `${this.stack}\n${missingStack}`;
    }
  }
}
