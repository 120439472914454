import React, { FC, useState } from 'react';
import { Footer, Sidebar, Topbar } from './components';
import { styled } from '@mui/system';
import { HEADER_HEIGHT } from 'assets/styles/constants';

const AppLayout = styled('div')(({ theme }) => ({
  paddingTop: `calc(${HEADER_HEIGHT} + ${theme.spacing(2)})`,
  display: 'flex',
  flexDirection: 'column',
}));

const MainContent = styled('main')(({ theme }) => ({
  height: '100%',
  flexGrow: 1,
  overflow: 'auto',
  padding: theme.spacing(2),
}));

const Main: FC = ({ children }) => {
  const [sidebarOpen, setOpenSidebar] = useState(false);

  const toggleSidebar = () => {
    setOpenSidebar(!sidebarOpen);
  };

  return (
    <AppLayout>
      <Topbar onSidebarToggle={toggleSidebar} sidebarOpen={sidebarOpen} />
      <Sidebar onSidebarToggle={toggleSidebar} sidebarOpen={sidebarOpen} variant="temporary" />
      <MainContent>{children}</MainContent>
      <Footer />
    </AppLayout>
  );
};

export default Main;
