import { createSlice } from '@reduxjs/toolkit';
import { Severity } from 'components/snackbar/Component';

type InitialSnackBarState = {
  isVisible: boolean;
  message: string;
  severity: Severity;
};

const initialState: InitialSnackBarState = {
  isVisible: false,
  message: '',
  severity: 'info',
};

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (_state, { payload }) => ({
      isVisible: true,
      ...payload,
    }),
    closeSnackbar: () => ({
      ...initialState,
    }),
  },
});

export const { openSnackbar, closeSnackbar } = snackbar.actions;

export default snackbar.reducer;
