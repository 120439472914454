export enum RoutePaths {
  Dashboard = '/dashboard', // "Sandėlio skydelis"
  ReplenishmentsValidation = '/replenishments-validation', // "Trūkstama informacija"
  InventoryForecasts = '/inventory-forecasts', // "Prognozės"
  InventoryReplenishments = '/inventory-replenishments', // "Atsargų poreikiai"
  InventoryAnalytics = '/inventory-analytics', // "Atsargų analitika"
  Promotions = '/promotions',
  PurchaseDashboard = '/purchase-dashboard', // "Pirkimų skydelis"
  Locations = '/locations',
  LocationForm = '/locations-form',
  Inventory = '/inventory',
  InventoryConsumables = '/inventory-consumables',
  Pallets = '/pallets',
  LocationClasses = '/location-classes',
  LocationClassForm = '/location-classes-form',
  PurchaseOrders = '/purchase-orders',
  PurchaseOrderForm = '/purchase-order-form',
  SalesOrders = '/sales-orders',
  SalesOrderForm = '/sales-order-form',
  InternalOperations = '/internal-operations',
  InternalOperationForm = '/internal-operation-form',
  Returns = '/returns',
  ReturnForm = '/returns-form',
  ManufacturingOrders = '/manufacturing-orders',
  ManufacturingOrderForm = '/manufacturing-order-form',
  ManufacturingOrdersDisassemble = '/manufacturing-orders-disassemble',
  ManufacturingOrderDisassembleForm = '/manufacturing-order-disassemble-form',
  SkuCatalogue = '/catalogue',
  SkuForm = '/catalogue-form',
  SkuCategories = '/sku-categories',
  SkuCategoryForm = '/sku-category-form',
  Uoms = '/uoms',
  UomForm = '/uom-form',
  UomCategories = '/uom-categories',
  UomCategoryForm = '/uom-category-form',
  Reports = '/reports',
  Contacts = '/contacts',
  ContactForm = '/contact-form',
  Tasks = '/tasks',
  Employees = '/employees',
  EmployeeForm = '/employee-form',
  ActionLogs = '/action-logs',
  RequestResponseLog = '/request-response-log',
  Settings = '/settings',
  BaseSettings = '/base-settings',
  OrderSettings = '/orders-settings',
  ManufacturingSettingsForm = '/manufacturing-settings-form',
  SalesSettingsForm = '/sales-settings-form',
  PurchaseSettingsForm = '/purchase-settings-form',
  ReturnsSettingsForm = '/returns-settings-form',
  InternalOpsOutboundSettingsForm = '/internal-operations-outbound-settings-form',
  InternalOpsInboundSettingsForm = '/internal-operations-inbound-settings-form',
  Login = '/login',
  Warehouses = '/warehouses',
  WarehouseForm = '/warehouse-form',
  ReceivingTasks = '/receiving-tasks',
  PutawayTasks = '/putaway-tasks',
  InProgressTasks = '/in-progress-tasks',
  ReceivingTaskWizard = '/receiving-task-wizard',
  PutawayTaskWizard = '/putaway-task-wizard',
}
