import { LocationNested } from 'redux/locations/types';

export const findBranchByIdAndToggle = (tree: LocationNested[], target: number): boolean => {
  if (tree) {
    return tree.some((branch: LocationNested & { toggled: boolean }) => {
      if (branch.id === target) {
        branch.toggled = !branch.toggled;
        return true;
      }
      return findBranchByIdAndToggle(branch.children, target);
    });
  }
  return false;
};

const toggleBranchActive = (tree: LocationNested[], target: number): boolean => {
  if (tree) {
    return tree.some((branch: LocationNested & { active: boolean }) => {
      if (branch.id === target) {
        branch.active = !branch.active;
        return true;
      }
      return toggleBranchActive(branch.children, target);
    });
  }
  return false;
};

const setBranchInactive = (tree: LocationNested[], target: number): boolean => {
  if (tree) {
    return tree.some((branch: LocationNested & { active: boolean }) => {
      if (branch.id === target) {
        branch.active = false;
        return true;
      }
      return setBranchInactive(branch.children, target);
    });
  }
  return false;
};

export const toggleTreeBranchExpanded = (state: LocationNested[] | null, { payload }: { payload: LocationNested }) => {
  const copiedState = JSON.parse(JSON.stringify(state));
  findBranchByIdAndToggle(copiedState, payload.id);
  return copiedState;
};

export const setActiveTreeBranch = (
  state: LocationNested[] | null,
  { payload }: { payload: { lastId: number | undefined; location: null | LocationNested } }
) => {
  const { location, lastId } = payload;
  const copiedState = JSON.parse(JSON.stringify(state));
  if (lastId) {
    if (!location || (location && location.id !== lastId)) {
      setBranchInactive(copiedState, lastId);
    }
  }
  if (location) {
    toggleBranchActive(copiedState, location.id);
  }
  return copiedState;
};
