import { createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { LocationClassResponse } from 'redux/locationClasses/types';
import { set, updateObjectsInArray } from '../utils';
import { Location, LocationNested } from './types';
import { setActiveTreeBranch, toggleTreeBranchExpanded } from 'utils/locations';

const resetSlice = createSlice({
  name: 'reset',
  initialState: {},
  reducers: {
    resetLocationsState: () => ({}),
  },
});

export const { resetLocationsState } = resetSlice.actions;

type InitialLocationsState = LocationNested[] | null;
const initialLocationsState = null as InitialLocationsState;

const locations = createSlice({
  name: 'locations',
  initialState: initialLocationsState,
  reducers: {
    setActiveLocation: (state, { payload }) => {
      return setActiveTreeBranch(state, { payload });
    },
    setAllLocations: (state, { payload }) => set(state, { payload }),
    toggleLocation: (state, { payload }) => toggleTreeBranchExpanded(state, { payload }),
  },
  extraReducers: builder => {
    builder.addCase(resetLocationsState, () => initialLocationsState);
  },
});
export const { toggleLocation, setAllLocations, setActiveLocation } = locations.actions;

type InitialActiveLocationState = Location | null;
const activeLocationInitialState = null as InitialActiveLocationState;

const activeLocation = createSlice({
  name: 'locations',
  initialState: activeLocationInitialState,
  reducers: {
    setActiveLocationInForm: (_state, { payload }) => payload,
    setActiveLocation: (_state, { payload }) => {
      return payload.location;
    },
  },
});
export const { setActiveLocationInForm } = activeLocation.actions;

type InitialLocationClassesState = LocationClassResponse[] | null;
const activeLocLocClassesState = null as InitialLocationClassesState;

const activeLocationLocationClasses = createSlice({
  name: 'activeLocationLocationClasses',
  initialState: activeLocLocClassesState,
  reducers: {
    setActiveLocationClasses: (_state, { payload }) => payload,
  },
});
export const { setActiveLocationClasses } = activeLocationLocationClasses.actions;

const childrenLocations = createSlice({
  name: 'childrenLocations',
  initialState: [],
  reducers: {
    setChildrenLocations: (_state, { payload }) => payload,
    updateChildrenLocations: (state, { payload }) => updateObjectsInArray(state, payload),
  },
  extraReducers: builder => {
    builder.addCase(resetLocationsState, () => []);
  },
});
export const { setChildrenLocations, updateChildrenLocations } = childrenLocations.actions;

const activeId = createSlice({
  name: 'activeId',
  initialState: null,
  reducers: {
    setActiveId: (_state, { payload }) => payload,
  },
});
export const { setActiveId } = activeId.actions;

export default combineReducers({
  locations: locations.reducer,
  activeLocation: activeLocation.reducer,
  childrenLocations: childrenLocations.reducer,
  activeId: activeId.reducer,
  activeLocationLocationClasses: activeLocationLocationClasses.reducer,
  reset: resetSlice.reducer,
});
