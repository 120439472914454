import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PutawayTaskStep, ReceivementTaskStep } from 'modules/webTasks/types';
import { State } from 'redux/store';
import { TaskResponse } from 'redux/tasks/types';

// Current task
const currentTaskSlice = createSlice({
  name: 'currentTask',
  initialState: null as TaskResponse | null,
  reducers: {
    setCurrentTask: (_state, { payload }: PayloadAction<TaskResponse>) => payload,
  },
});

export const { setCurrentTask } = currentTaskSlice.actions;
export const selectCurrentTask = (state: State) => state.webTasks.currentTask;

// Receiving
const receivementTaskStepsSlice = createSlice({
  name: 'receivmentTaskSteps',
  initialState: null as ReceivementTaskStep[] | null,
  reducers: {
    setReceivementTaskSteps: (_state, { payload }: PayloadAction<ReceivementTaskStep[]>) => payload,
  },
});

export const { setReceivementTaskSteps } = receivementTaskStepsSlice.actions;
export const selectReceivementTaskSteps = (state: State) => state.webTasks.receivementTaskSteps;

// Putaway
const putawayTaskStepsSlice = createSlice({
  name: 'putawayTaskSteps',
  initialState: null as PutawayTaskStep[] | null,
  reducers: {
    setPutawayTaskSteps: (_state, { payload }: PayloadAction<PutawayTaskStep[]>) => payload,
    updatePutawayTaskStepCompletion(state, { payload }: PayloadAction<{ stepId: number; completed: boolean }>) {
      const step = state?.find(step => step.id === payload.stepId);
      if (step) step.completed = payload.completed;
    },
  },
});
export const { setPutawayTaskSteps, updatePutawayTaskStepCompletion } = putawayTaskStepsSlice.actions;
export const selectPutawayTaskSteps = (state: State) => state.webTasks.putawayTaskSteps;

export default combineReducers({
  receivementTaskSteps: receivementTaskStepsSlice.reducer,
  putawayTaskSteps: putawayTaskStepsSlice.reducer,
  currentTask: currentTaskSlice.reducer,
});
