import { Button, Hidden, IconButton, Theme } from '@mui/material';
import React from 'react';
import { useKBar } from 'kbar';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { useSearchButton } from './SearchButtonContext';
import { isMacOS } from 'utils/osHelpers';
import { GAEventAction, GAEventCategory, trackCustomGAEvent } from 'utils/googleAnalytics';

const useStyles = makeStyles((theme: Theme) => ({
  searchBox: {
    borderRadius: '10px',
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 8,
    width: 250,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  searchBoxText: {
    textTransform: 'capitalize',
    flex: 1,
    alignSelf: 'flex-start',
    textAlign: 'left',
  },
  tag: {
    display: 'flex',
    gap: 4,
    backgroundColor: '#0000001a',
    borderRadius: 6,
    padding: '0 4px',
    fontSize: 12,
    textTransform: 'capitalize',
  },
}));

const COMMAND = isMacOS() ? `Cmd + K` : `Ctrl + K`;

const SearchButton = () => {
  const { setSearchButtonDisabled, searchButtonDisabled } = useSearchButton();
  const classes = useStyles();
  const { t } = useTranslation();
  const { query } = useKBar();

  const handleClick = () => {
    query.toggle();
    trackCustomGAEvent(GAEventCategory.SEARCH_BAR, GAEventAction.CLICK_BUTTON);
    setSearchButtonDisabled(true);
  };
  return (
    <>
      <Hidden smUp>
        <IconButton onClick={handleClick} disabled={searchButtonDisabled}>
          <SearchOutlinedIcon />
        </IconButton>
      </Hidden>
      <Hidden smDown>
        <Button
          className={classes.searchBox}
          startIcon={<SearchOutlinedIcon />}
          variant="outlined"
          onClick={handleClick}
          disabled={searchButtonDisabled}
        >
          <span className={classes.searchBoxText}>{t('common:search')}</span>
          <div className={classes.tag}>{COMMAND}</div>
        </Button>
      </Hidden>
    </>
  );
};

export default SearchButton;
