import { Parameter } from 'api';
import {
  MRT_ColumnDef,
  MRT_ColumnFiltersState,
  MRT_SortingState,
  type MRT_RowData, type MRT_TableOptions
} from 'material-react-table';
import { ColumnSetting, ExportType, ExportVariant } from './types';
import i18n from 'i18n';

import { downloadBlob } from 'utils/downloadBlob';
import { MRT_RowModel, MRT_RowSelectionState } from 'material-react-table';
import { EndpointKeys, exportToFile } from 'api/exportTableDataToFile';

export type MIMEType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' | 'text/csv';
export interface DataExportParams {
  export_all: boolean;
  export_sku_ids?: string[];
  export_promotion_ids?: string[]
  filters?: { field: string; value: unknown }[];
  sort?: { field: string; direction: string }[];
  calculationsStartDate?: string;
}

export const fileTypeToMIMEType: { [key in ExportType]: MIMEType } = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
};
interface EndpointConfig {
  [key: string]: {
    exportIdField: 'export_promotion_ids' | 'export_sku_ids';
  };
}

const endpointConfig: EndpointConfig = {
  promotions: {
    exportIdField: 'export_promotion_ids',
  },
  default: {
    exportIdField: 'export_sku_ids',
  },
};

export async function exportDataToFile(
  endpoint: EndpointKeys,
  exportType: ExportVariant,
  fileType: ExportType,
  fileName: string,
  getPaginationRowModel: MRT_RowModel<any>,
  columnFilters?: MRT_ColumnFiltersState,
  sorting?: MRT_SortingState,
  rowSelection?: MRT_RowSelectionState,
  calculationsStartDate?: string,
  expectedDeliveryTimeStart?: string,
  expectedDeliveryTimeEnd?: string,
) {
  const exportAll = exportType === ExportVariant.ALL;
  const config = endpointConfig[endpoint] || endpointConfig.default
  const exportParams: DataExportParams = {
    export_all: exportAll,
    [config.exportIdField]: [],
    ...(calculationsStartDate && { calculationsStartDate }),
    ...(expectedDeliveryTimeStart && { expectedDeliveryTimeStart }),
    ...(expectedDeliveryTimeEnd && { expectedDeliveryTimeEnd }),
  };
  if (!exportAll) {
    const ids = exportType === ExportVariant.CURRENT_PAGE
      ? getPaginationRowModel.rows.map(row => row.original.id.toString())
      : exportType === ExportVariant.SELECTED && rowSelection
      ? Object.keys(rowSelection).map(id => id.toString())
      : [];

    exportParams[config.exportIdField] = ids;

    if (exportType === ExportVariant.FILTERED) {
      exportParams.filters = columnFilters?.map(({ id, value }) => ({
        field: id,
        value,
      }));
      exportParams.sort = sorting?.map(({ id, desc }) => ({
        field: id,
        direction: desc ? 'desc' : 'asc',
      }));
    }
  }

  const mimeType: MIMEType = fileTypeToMIMEType[fileType];

  try {
    const response = await exportToFile(endpoint, mimeType, exportParams);
    const blob = new Blob([response], { type: mimeType });
    downloadBlob(blob, `${fileName}.${fileType}`);
  } catch (error) {
    console.error('Error generating or downloading the file:', error);
  }
}

export const selectParametersFromFilter = ({ column, value }: { column: { filterName: string; field: string; type: any };  value: any}) => {
  const filterKey = column.filterName || column.field;
  if (value) {
    // handle array values
    if (typeof value === 'object') {
      if (value.length) {
        const parameters = value.map((v: any) => {
          if (v) {
            return { key: `${filterKey}[]`, value: v };
          }
        });
        return parameters;
      }
    } else if (column.type === 'boolean') {
      // handle booleans
      return { key: filterKey, value: value === 'checked' };
    } else {
      return { key: filterKey, value };
    }
  }
  return;
};

export const buildApiParams = (
  pageSize: number,
  pageIndex: number,
  sorting?: MRT_SortingState,
  columnFilters?: MRT_ColumnFiltersState,
  paramsFromState?: Parameter[]
) => {
  const params: Parameter[] = [];

  if (sorting && sorting.length > 0) {
    const { id: accessorKey, desc: direction } = sorting[0];
    params.push({ key: `${accessorKey}[orderBy]`, value: direction ? 'desc' : 'asc' });
  }

  if (columnFilters && columnFilters.length > 0) {
    columnFilters.forEach(filter => {
      const { id, value } = filter;
      const filterParams = selectParametersFromFilter({
        column: { filterName: id, field: id, type: typeof value },
        value,
      });
      if (Array.isArray(filterParams)) {
        params.push(...filterParams);
      } else if (filterParams) {
        params.push(filterParams);
      }
    });
  }
  if (paramsFromState && paramsFromState.length > 0) {
    paramsFromState.forEach(param => {
      params.push(param);
    });
  }

  params.push({ key: 'perPage', value: Number(pageSize) });
  params.push({ key: 'page', value: pageIndex + 1 });

  return params;
};

export const hideColumns = (columns: MRT_ColumnDef<any>[], columnSettings?: ColumnSetting[]) => {
  if (!columnSettings) return columns;

  const visibilitySettings = Object.fromEntries(columnSettings.map(setting => [setting.id || setting.field, setting]));

  return columns.filter(column => {
    const identifier = column.id ? column.id : (column.accessorKey as string);
    const clientSettingsVisibility = visibilitySettings[identifier];
    return clientSettingsVisibility?.hidden !== true;
  });
};

export const getDefaultMRTOptions = <TData extends MRT_RowData>(
): Partial<MRT_TableOptions<TData>> => {
  return {
    initialState: { pagination: { pageSize: 15, pageIndex: 0 } },
    state: {
      density: 'compact',
      showProgressBars: false,
      showToolbarDropZone: false,
    },
    positionToolbarAlertBanner: 'none',
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableFullScreenToggle: false,
    enableHiding: false,
    enableFilterMatchHighlighting: false,
    enableColumnActions: false,
    enableColumnFilterModes: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: {
        backgroundColor: '#fff',
      },
    },
    muiTablePaperProps: {
        sx: { padding: "0 16px 0 16px" }
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#fff',
        boxShadow: 'none',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        fontSize: '13px',
        fontWeight: 500,
        verticalAlign: 'initial !important',
        boxShadow: 'none',
        // Hide filter icon, because of runtime error;
        // https://github.com/KevinVandy/material-react-table/issues/664
        '& .Mui-TableHeadCell-Content-Labels .MuiButtonBase-root.MuiIconButton-root': {
          display: 'none',
        },
      },
    },
    muiTableHeadRowProps: {
      sx: {
        boxShadow: 'none',
        backgroundColor: '#fff',
      },
    },
    muiFilterTextFieldProps: {
      placeholder: i18n.t('Filter'),
      sx: {
        marginLeft: 'unset',
        marginRight: 'unset',
      }
    },
    muiTableBodyProps: {
      sx: {
        '& td': {
          backgroundColor: '#fff',
        },
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#fff',
        borderBlockEnd: '1px solid #eeee',
      },
    },
  };
};



