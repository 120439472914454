import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { ReturnOrderProduct, ReturnResponse } from './types';
import { removeObjectFromArray, updateObjectInArray } from 'redux/utils';

type InitialOrderState = ReturnResponse | null;
const activeOrderInitialState = null as InitialOrderState;

const activeReturn = createSlice({
  name: 'activeReturn',
  initialState: activeOrderInitialState,
  reducers: {
    setActiveReturn: (_, { payload }: PayloadAction<ReturnResponse | null>) => payload,
    updateActiveReturn: (state, { payload }: PayloadAction<Partial<ReturnResponse>>) => {
      if (state) {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
});
export const { setActiveReturn, updateActiveReturn } = activeReturn.actions;

type InitialActiveIdState = string | null;
const activeIdState = null as InitialActiveIdState;

const activeId = createSlice({
  name: 'activeId',
  initialState: activeIdState,
  reducers: {
    setActiveReturnId: (_, { payload }: PayloadAction<string | null>) => payload,
  },
});
export const { setActiveReturnId } = activeId.actions;

type InitialPOProductsState = ReturnOrderProduct[] | null;
const productsInitialState = null as InitialPOProductsState;

const products = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setROProducts: (_state, { payload }: PayloadAction<ReturnOrderProduct[] | null>) => payload,
    addROProduct: (state, { payload }: PayloadAction<ReturnOrderProduct>) => {
      if (!state) {
        return;
      }
      state.push(payload);
    },
    updateROProduct: (state, { payload }: PayloadAction<ReturnOrderProduct>) => updateObjectInArray(state, { payload }),
    deleteROProduct: (state, { payload }: PayloadAction<{ id: number }>) => removeObjectFromArray(state, { payload }),
  },
});

export const { setROProducts, addROProduct, updateROProduct, deleteROProduct } = products.actions;

export default combineReducers({
  activeId: activeId.reducer,
  activeReturn: activeReturn.reducer,
  products: products.reducer,
});
