import { AjaxError } from '../../types/ajaxError';
import { PaginatedAPIResponse } from '../../types/paginatedApiResponse';

export interface AggregationPayload {
  aggregation_type: string;
  aggregation_id: number;
  interval: string;
  date_from: string;
  date_to: string;
}

// TYPES
export interface AggregatedCompletedSales {
  uom_value: number;
  count: string;
  week_number: string;
  year: string;
  label: string;
}

export interface AggregatedForecastedSales {
  forecasted_quantity: number;
  count: number;
  forecasted_week_number: string;
  forecasted_year: string;
  label: string;
}

export interface SkuInventoryBalance {
  inventory_balance: string;
  count: string;
  week: string;
  year: string;
  label: string;
}

export interface SkuInventoryPurchaseOrderProduct {
  uom_value: number;
  month: string;
  year: string;
  arriving_at: string;
  label: string;
}

export interface ProcessedForecastedSales {
  forecasted_quantity: string;
  forecasted_quantity_with_promotion: string;
  count: string;
  week: string;
  year: string;
  label: string;
}
export interface AggregatedSkuSalesPromotions {
  quantity: string;
  count: string;
  week_number: string;
  year: string;
  label: string;
}
export interface SkuSalesForecast {
  id: number;
  sku_name: string;
  sku_code: string;
  buffer: number;
  transit_time_days_air: number;
  transit_time_days_ground: number;
  transit_time_days_sea: number;
  category_name: string;
  product_manager_id: number;
  product_manager_name: string;
  supplier_name: string;
  available_quantity: number;
  arriving_quantity: number;
  processed_forecasted_quantity: number;
  forecasted_interval: string;
  forecasted_date: string;
  stock_level: string;
  forecasted_quantity: number;
  interval: string;
}

export enum AggregationIntervals {
  Day = 'day',
  Month = 'month',
  Week = 'week',
  Default = 'week',
}

// INCOMING TYPES
export type AggregatedCompletedSalesAPIResponse = AggregatedCompletedSales[] | AjaxError;
export type AggregatedForecastedSalesAPIResponse = AggregatedForecastedSales[] | AjaxError;
export type SkuSalesForecastAPIResponse = PaginatedAPIResponse<SkuSalesForecast> | AjaxError;
