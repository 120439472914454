import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateObjectInArray, removeObjectFromArray, pushToArray } from 'redux/utils';
import { ManufacturingOrder, MOKit } from 'types/manufacturingOrders';

type InitialDataState = ManufacturingOrder[];
const initialDataState = [] as InitialDataState;
const data = createSlice({
  name: 'data',
  initialState: initialDataState,
  reducers: {
    createMODisassemble: (state, { payload }) => pushToArray(state, { payload }),
  },
});
export const { createMODisassemble } = data.actions;

type InitialOrderState = ManufacturingOrder | null;
const activeOrderInitialState = null as InitialOrderState;
const activeOrder = createSlice({
  name: 'activeOrder',
  initialState: activeOrderInitialState,
  reducers: {
    setActiveManufacturingDisassembleOrder: (_, { payload }) => payload,
    updateActiveManufacturingDisassembleOrder: (state, { payload }) => {
      if (state) {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
});
export const {
  setActiveManufacturingDisassembleOrder,
  updateActiveManufacturingDisassembleOrder,
} = activeOrder.actions;

interface StateMOProduct extends MOKit {
  active?: boolean;
}
type ProductsInitialState = StateMOProduct[] | null;
const productsInitialState = null as ProductsInitialState;
const products = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setMODisassembleProducts: (_state, { payload }) => payload,
    setActiveMODisassembleProduct: (state, { payload }) => {
      const active = state?.map(prod => ({
        ...prod,
        active: prod.id === payload && !prod.active,
      }));
      return active;
    },
    addMODisassembleProduct: (state, { payload }) => {
      state?.push(payload);
    },
    updateMODisassembleProduct: (state, { payload }) => updateObjectInArray(state, { payload }),
    deleteMODisassembleProduct: (state, { payload }) => removeObjectFromArray(state, { payload }),
  },
});
export const {
  setMODisassembleProducts,
  addMODisassembleProduct,
  updateMODisassembleProduct,
  deleteMODisassembleProduct,
  setActiveMODisassembleProduct,
} = products.actions;

const reservedInventory = createSlice({
  name: 'reservedInventory',
  initialState: null,
  reducers: {
    setActiveMODisassembleReservedInventory: (_state, { payload }) => payload,
  },
});
export const { setActiveMODisassembleReservedInventory } = reservedInventory.actions;

type InitialActiveIdState = string | null;
const activeIdState = null as InitialActiveIdState;

const activeId = createSlice({
  name: 'activeId',
  initialState: activeIdState,
  reducers: {
    setActiveManufacturingDisassembleOrderId: (_, { payload }: PayloadAction<string | null>) => payload,
  },
});
export const { setActiveManufacturingDisassembleOrderId } = activeId.actions;

const selectedData = createSlice({
  name: 'selectedData',
  initialState: [],
  reducers: {
    setSelectedMODisassembles: (_, { payload }) => payload,
  },
});
export const { setSelectedMODisassembles } = selectedData.actions;

export default combineReducers({
  data: data.reducer,
  selectedData: selectedData.reducer,
  activeId: activeId.reducer,
  activeOrder: activeOrder.reducer,
  reservedInventory: reservedInventory.reducer,
  products: products.reducer,
});
