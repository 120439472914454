import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserJSON } from 'redux/employees/types';
import _ from 'lodash';
import { State } from 'redux/store';

const authenticatedUserSlice = createSlice({
  name: 'authenticatedUser',
  initialState: null as UserJSON | null,
  reducers: {
    setUser: (_state, { payload }: PayloadAction<UserJSON>) => payload,
    updateUser: (state, { payload }: PayloadAction<Partial<UserJSON>>) => {
      if (state) {
        return _.merge({}, state, payload);
      }
    },
    clearUser: () => null,
  },
});

export const { setUser, updateUser, clearUser } = authenticatedUserSlice.actions;
export const selectAuthenticatedUser = (state: State) => state.authenticatedUser;
export const selectUserGroupName = (state: State) => state.authenticatedUser?.access_group?.name || null;
export const selectUserWarehouseId = (state: State) => state.authenticatedUser?.warehouse_id;
export const selectUserEmail = (state: State) => state.authenticatedUser?.email;

export default authenticatedUserSlice.reducer;
