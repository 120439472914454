import { fetchAPI, fetchAPIResponse, fetchAxiosAPI, Parameter, RequestMethod } from 'api';
import {
  CreateUserAPIPayload,
  ResetPasswordAPIPayload,
  UpdateUserAPIPayload,
  UserAPIResponse,
  UsersAPIResponse,
} from 'redux/employees/apiTypes';
import { ActionLogsAPIResponse } from 'types/actionsLog';
import { BASE_ACTION_LOGS_URL, BASE_CLIENT_SETTINGS_URL, BASE_USERS_URL } from './constants';

export enum UserActions {
  ENABLE = 'enable',
  DISABLE = 'disable',
  DELETE = 'delete',
  SET_PASSWORD = 'set-password',
}

// Fetches
export const fetchClientSettings = async (parameters: Parameter[]) =>
  fetchAPIResponse(BASE_CLIENT_SETTINGS_URL, parameters);
export const fetchActionsHistoryAPI = async (parameters: Parameter[]) =>
  fetchAPI<ActionLogsAPIResponse>(BASE_ACTION_LOGS_URL, parameters);

// Employees fetches
export const fetchUsersAPI = async () => fetchAPI<UsersAPIResponse>(BASE_USERS_URL);
export const fetchUserAPI = async (id: string) => fetchAPI<UserAPIResponse>(`${BASE_USERS_URL}/${id}`);

// Employees CRUD
export const createUserAPI = async (payload: CreateUserAPIPayload) =>
  fetchAxiosAPI<UserAPIResponse>(BASE_USERS_URL, RequestMethod.POST, payload);
export const updateUserAPI = async (payload: UpdateUserAPIPayload, id: string) =>
  fetchAxiosAPI<UserAPIResponse>(`${BASE_USERS_URL}/${id}`, RequestMethod.PATCH, payload);

// Employees ACIONS
export const enableUserActionAPI = async (id: string) =>
  fetchAxiosAPI<UserAPIResponse>(`${BASE_USERS_URL}/${id}/actions/${UserActions.ENABLE}`, RequestMethod.POST);
export const disableUserActionAPI = async (id: string) =>
  fetchAxiosAPI<UserAPIResponse>(`${BASE_USERS_URL}/${id}/actions/${UserActions.DISABLE}`, RequestMethod.POST);
export const deleteUserActionAPI = async (id: string) =>
  fetchAxiosAPI(`${BASE_USERS_URL}/${id}/actions/${UserActions.DELETE}`, RequestMethod.POST);
export const changeUserPasswordActionAPI = async (id: string, payload: ResetPasswordAPIPayload) =>
  fetchAxiosAPI(`${BASE_USERS_URL}/${id}/actions/${UserActions.SET_PASSWORD}`, RequestMethod.POST, payload);
