import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { pushToArray } from '../utils';
import { SkuResponse } from './apiTypes';
import { Consumable, SkuCategory, SkuLocationClassResponse } from './types';

type Data = {
  id: string;
};

const data = createSlice({
  name: 'data',
  initialState: [] as Data[],
  reducers: {
    forceRefresh: (state, { payload }) => pushToArray(state, { payload }),
  },
});
export const { forceRefresh } = data.actions;

type InitialActiveSkuState = SkuResponse | null;
const initialActiveSkuState = null as InitialActiveSkuState;

const activeSku = createSlice({
  name: 'activeSku',
  initialState: initialActiveSkuState,
  reducers: {
    setActiveSku: (_state, { payload }: PayloadAction<InitialActiveSkuState>) => payload,
  },
});
export const { setActiveSku } = activeSku.actions;

type InitialActiveIdState = number | null;
const activeSkuId = createSlice({
  name: 'activeSkuId',
  initialState: null as InitialActiveIdState,
  reducers: {
    setActiveSkuId: (_state, { payload }: PayloadAction<null | number>) => payload,
  },
});
export const { setActiveSkuId } = activeSkuId.actions;

type InitialSkuCategoriesState = SkuCategory[] | [];
const initialSkuCategoriesState = [] as InitialSkuCategoriesState;

const skuCategories = createSlice({
  name: 'skuCategories',
  initialState: initialSkuCategoriesState,
  reducers: {
    setSkuCategories: (_state, { payload }) => payload,
  },
});
export const { setSkuCategories } = skuCategories.actions;

export type InitialActiveSkuCategoryState = SkuCategory | null;
const initialActiveSkuCategoryState = null as InitialActiveSkuCategoryState;

const activeSkuCategory = createSlice({
  name: 'activeSkuCategory',
  initialState: initialActiveSkuCategoryState,
  reducers: {
    setActiveSkuCategory: (_state, { payload }) => payload,
  },
});
export const { setActiveSkuCategory } = activeSkuCategory.actions;

type InitialActiveSkuCatIdState = number | null;
const initialState = null as InitialActiveSkuCatIdState;

const activeSkuCategoryId = createSlice({
  name: 'activeSkuCategoryId',
  initialState: initialState,
  reducers: {
    setActiveSkuCategoryId: (_state, { payload }) => payload,
  },
});
export const { setActiveSkuCategoryId } = activeSkuCategoryId.actions;

type InitialLocationClassesState = SkuLocationClassResponse[] | null;
const initialLocationClassesState = null as InitialLocationClassesState;

const locationClasses = createSlice({
  name: 'locationClasses',
  initialState: initialLocationClassesState,
  reducers: {
    setActiveSkuLocationClasses: (_state, { payload }) => payload,
    addActiveSkuLocationClass: (state, { payload }) =>
      state?.length ? [...state, payload] : [payload],
    removeActiveSkuLocationClass: (state, { payload }) => {
      if (!state) {
        return null;
      }
      const index = state?.findIndex(locClass => locClass.id === payload);
      state?.splice(index as number, 1);
      return state;
    },
  },
});

export const {
  setActiveSkuLocationClasses,
  removeActiveSkuLocationClass,
  addActiveSkuLocationClass,
} = locationClasses.actions;

type InitialConsumablesState = Consumable[] | null;
const initialConsumablesState = null as InitialConsumablesState;

const consumables = createSlice({
  name: 'consumables',
  initialState: initialConsumablesState,
  reducers: {
    setActiveSkuConsumables: (_state, { payload }) => payload,
    addConsumableToSku: (state, { payload }) => (state?.length ? [...state, payload] : [payload]),
    removeConsumableFromSku: (state, { payload }) => {
      if (!state) {
        return null;
      }
      const index = state?.findIndex(consumable => consumable.id === payload);
      state?.splice(index as number, 1);
      return state;
    },
  },
});

export const {
  setActiveSkuConsumables,
  addConsumableToSku,
  removeConsumableFromSku,
} = consumables.actions;

export default combineReducers({
  data: data.reducer,
  activeSku: activeSku.reducer,
  activeSkuId: activeSkuId.reducer,
  skuCategories: skuCategories.reducer,
  activeSkuCategory: activeSkuCategory.reducer,
  activeSkuCategoryId: activeSkuCategoryId.reducer,
  locationClasses: locationClasses.reducer,
  consumables: consumables.reducer,
});
