import { List } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { selectDisabledRoutes } from 'redux/settings/selectors';
import SidebarNavItem from './SidebarNavItem';

const SidebarNav = ({ disabledRoutes, pages, sidebarOpen, onSidebarToggle, className }) => (
  <List className={className}>
    {pages.map(page => {
      return (
        <SidebarNavItem
          key={page.id}
          disabledRoutes={disabledRoutes}
          sidebarOpen={sidebarOpen}
          onSidebarToggle={onSidebarToggle}
          {...page}
        />
      );
    })}
  </List>
);

const mapStateToProps = state => ({
  disabledRoutes: selectDisabledRoutes(state),
});

export default connect(mapStateToProps)(SidebarNav);
