import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
}));

interface Props {
  className?: string;
}

const Topbar: FC<Props> = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary" position="fixed">
      <Toolbar>
        <RouterLink to="/" />
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
