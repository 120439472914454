import { openSnackbar } from 'redux/snackbar/reducers';

export const hasAPIError = response => dispatch => {
  if (response && response.error && response.error.messages) {
    const { messages } = response.error;
    const errorKeys = Object.keys(messages);
    if (errorKeys.length) {
      dispatch(openSnackbar({ message: messages[errorKeys[0]][0], severity: 'error' }));
    }
    return true;
  }
  return false;
};
