const getApiConfig = (warehouseId?: number | null) => {
  return {
    baseUrl: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      Identification: `Bearer ${localStorage.getItem('id_token')}`,
      'Content-Type': 'application/json',
      'Accept-Language': 'lt',
      ...(warehouseId && { 'X-Warehouse-Id': warehouseId.toString() }),
    },
  };
};
export default getApiConfig;
