import { css } from '@emotion/core';
import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const override = css`
  display: block;
  margin: 0 auto;
`;

function Spinner() {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}
    >
      <ClipLoader color="#35baf6" loading css={override} size={150} />
    </div>
  );
}

export default Spinner;
