import { createSlice } from '@reduxjs/toolkit';

export const DATE_INPUT_DIALOG_VISIBILITY_KEY = 'DATE_INPUT_DIALOG_VISIBILITY_KEY';
export const ACTION_DIALOG_VISIBILITY_KEY = 'CONFIRM_DIALOG_VISIBILITY_KEY';
export const INVOICES_EXPORT_DIALOG_VISIBILITY_KEY = 'PRAGMA_DIALOG_VISIBILITY_KEY';
export const MO_INVOICES_EXPORT_DIALOG_VISIBILITY_KEY = 'MO_INVOICES_EXPORT_DIALOG_VISIBILITY_KEY';
export const SPLIT_INVENTORY_VISIBILITY_KEY = 'SPLIT_INVENTORY_VISIBILITY_KEY';

interface InitialState {
  [key: string]: {
    key: string;
    value: boolean;
    params: {
      title: string;
      secondaryTitle: string;
      onConfirmCallback: (callbackParams: string) => void;
      callbackParams: string;
      primaryTitle?: string;
      contentText?: string;
    };
  };
}

const initialState = ({
  [DATE_INPUT_DIALOG_VISIBILITY_KEY]: {
    key: [DATE_INPUT_DIALOG_VISIBILITY_KEY],
    value: false,
    params: null,
  },
  [ACTION_DIALOG_VISIBILITY_KEY]: {
    key: [ACTION_DIALOG_VISIBILITY_KEY],
    value: false,
    params: null,
  },
  [INVOICES_EXPORT_DIALOG_VISIBILITY_KEY]: {
    key: [INVOICES_EXPORT_DIALOG_VISIBILITY_KEY],
    value: false,
    params: null,
  },
  [MO_INVOICES_EXPORT_DIALOG_VISIBILITY_KEY]: {
    key: [MO_INVOICES_EXPORT_DIALOG_VISIBILITY_KEY],
    value: false,
    params: null,
  },
  [SPLIT_INVENTORY_VISIBILITY_KEY]: {
    key: [SPLIT_INVENTORY_VISIBILITY_KEY],
    value: false,
    params: null,
  },
} as unknown) as InitialState;

const visibility = createSlice({
  name: 'visibility',
  initialState: initialState,
  reducers: {
    setVisibility: (state, action) => ({
      ...state,
      [action.payload.key]: { value: action.payload.value, params: action.payload.params },
    }),
  },
});

export const { setVisibility } = visibility.actions;

export default visibility.reducer;
