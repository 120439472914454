import axios from 'axios';
import { RoutePaths } from 'constants/routePaths';
import { AjaxError } from 'types/ajaxError';
import store from 'redux/store';
import { history } from 'redux/history';
import { selectUserWarehouseId } from 'redux/user/userSlice';

const appendParamsToUrl = (url: string, params?: Parameter[]) => {
  let urlParameters = '';

  if (params && params.length) {
    urlParameters += '?';
    params.forEach((param, index) => {
      if (index) {
        urlParameters += '&';
      }
      urlParameters += `${param.key}=${param.value}`;
    });
  }

  return `${url}${urlParameters}`;
};

export interface Parameter {
  key: string;
  value: string | number | boolean;
}

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export const fetchAPI = async <T = any>(url: string, params?: Parameter[]): Promise<T | AjaxError> => {
  let urlParameters = '';
  if (params && params.length) {
    urlParameters += '?';
    params.forEach((param, index) => {
      if (index) {
        urlParameters += '&';
      }
      urlParameters += `${param.key}=${encodeURIComponent(param.value)}`;
    });
  }
  const requestUrl = `${process.env.REACT_APP_API_URL}/${url}${urlParameters}`;
  // TODO: Would be better to use RTKQ to access state
  const state = store.getState();
  const warehouseId = selectUserWarehouseId(state);
  try {
    const result = await fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Identification: `Bearer ${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
        'Accept-Language': 'lt',
        ...(warehouseId && {
          'X-Warehouse-Id': warehouseId.toString(),
        }),
      },
    });

    const jsonResponse = await result.json();
    if (result.ok) {
      return jsonResponse as T;
    }
    const error = (result as unknown) as AjaxError;
    if (error && error.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_bearer');
      localStorage.removeItem('expires_in');
      history.push(RoutePaths.Login);
    }
    // Do not show snackbar if status 404, we don't need to show not found error message in this case.
    if (error && error.status === 404) {
      return error;
    }
    return new AjaxError(JSON.stringify(jsonResponse?.error?.messages));
  } catch (err) {
    return new AjaxError('Nenumatyta klaida!');
  }
};

export const fetchAxiosAPI = async <T = any>(
  url: string,
  method: RequestMethod,
  data = {},
  options: any = {},
  params?: Parameter[]
) => {
  const requestUrl = appendParamsToUrl(`${process.env.REACT_APP_API_URL}/${url}`, params);
  // TODO: Would be better to use RTKQ to access state
  const state = store.getState();
  const warehouseId = selectUserWarehouseId(state);
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
    Identification: `Bearer ${localStorage.getItem('id_token')}`,
    'Content-Type': 'application/json',
    'Accept-Language': 'lt',
    ...(warehouseId && { 'X-Warehouse-Id': warehouseId.toString() }),
    ...(options.headers || {}),
  };

  const response = await axios.request<T, any>({
    ...options,
    headers,
    method,
    url: requestUrl,
    data,
    validateStatus: () => {
      return true;
    },
  });
  const errorStatus = (response as any)?.data?.error?.status;
  if (errorStatus === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('token_bearer');
    localStorage.removeItem('expires_in');
    history.push(RoutePaths.Login);
  }

  if (response?.data?.error?.messages) {
    const errorMessage = JSON.stringify(response.data.error.messages);

    return new AjaxError(errorMessage);
  }
  // TODO: this format error is returned when adding inventory with error
  // if (response?.data?.error?.messages.message?.length) {
  //   const errorMessage = JSON.stringify(response?.data?.error?.messages.message[0]);

  //   return new AjaxError(errorMessage);
  // }

  return response.data;
};

//todo remove this
export const fetchAPIResponse = async (url: string, params?: Parameter[]) => {
  let result;
  // TODO: Would be better to use RTKQ to access state
  const state = store.getState();
  const warehouseId = selectUserWarehouseId(state);
  const requestUrl = appendParamsToUrl(`${process.env.REACT_APP_API_URL}/${url}`, params);

  try {
    result = await fetch(requestUrl, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        Identification: `Bearer ${localStorage.getItem('id_token')}`,
        'Content-Type': 'application/json',
        'Accept-Language': 'lt',
        ...(warehouseId && { 'X-Warehouse-Id': warehouseId.toString() }),
      },
    });

    if (result.status === 401) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_bearer');
      localStorage.removeItem('expires_in');
      history.push(RoutePaths.Login);
    }
  } catch (err) {
    return null;
  }
  return result;
};
