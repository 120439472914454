const updateObjectInArray = (state, { payload }) => {
  if (!state || !state.length) {
    return [payload];
  }
  return state.map(item => {
    if (item.id === payload.id) {
      return { ...item, ...payload };
    }
    return item;
  });
};

const updateObjectsInArrayByKey = (state, { payload, payloadKey }) => {
  return state.map(item => ({
    ...item,
    counted_inventory: payload.find(p => p[payloadKey] === item.id),
  }));
};

// Find specifiied item in array by id, updates - item.key
const updateNestedValueInObjectInArray = (state, { payload }) => {
  return state.map(item => {
    if (item.id === payload.id) {
      return {
        ...item,
        [payload.key]: payload.data,
      };
    }
    return item;
  });
};

const updateObjectsInArray = (state, { payload }, key) =>
  key
    ? state.map(item => payload[key].find(el => el.id === item.id) || item)
    : state.map(item => payload.find(el => el.id === item.id) || item);

const removeObjectFromArray = (state, { payload }) => {
  if (state && state.length) {
    const index = state.findIndex(item => item.id === payload.id);
    return [...state.slice(0, index), ...state.slice(index + 1)];
  }
  return [];
};

const pushToArray = (state, { payload }) => {
  if (!state || !state.length) {
    return [payload];
  }
  return [payload, ...state];
};

const update = (state, { payload }) => ({ ...state, ...payload });

const set = (_state, { payload }) => payload;

export {
  updateObjectInArray,
  updateObjectsInArray,
  removeObjectFromArray,
  update,
  pushToArray,
  set,
  updateNestedValueInObjectInArray,
  updateObjectsInArrayByKey,
};
