import { Theme } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { ActionImpl, KBarAnimator, KBarPortal, KBarPositioner, KBarResults, KBarSearch, useMatches } from 'kbar';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import KBarResultsItem from './KBarResultsItem';
import { useLocation } from 'react-router-dom';
import { GAEventAction, GAEventCategory, trackCustomGAEvent } from 'utils/googleAnalytics';
import { debounce } from 'lodash';

const useStyles = makeStyles((_theme: Theme) => ({
  animator: {
    maxWidth: '600px',
    width: '100%',
    background: 'rgb(252 252 252)',
    color: ' rgb(28 28 29)',
    borderRadius: '8px',
    overflow: 'hidden',
    boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
  },
  search: {
    padding: '12px 16px',
    fontSize: '16px',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    border: 'none',
    background: 'rgb(252 252 252)',
    color: ' rgb(28 28 29)',
  },
  groupName: {
    padding: '8px 16px',
    fontSize: '10px',
    textTransform: 'uppercase',
    opacity: 0.5,
  },
}));

const RenderResults = ({ classes }: { classes: ClassNameMap }) => {
  const { results, rootActionId } = useMatches();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [selectedItem, setSelectedItem] = React.useState<ActionImpl | null | string>(null);

  useEffect(() => {
    const currentMatch = results.find(item => typeof item !== 'string' && currentUrl.includes(item.id));
    setSelectedItem(currentMatch ? currentMatch : null);
  }, [currentUrl]);

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === 'string' ? (
          <div className={classes.groupName}>{item}</div>
        ) : (
          <KBarResultsItem
            action={item}
            active={active}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            currentRootActionId={rootActionId!}
            selected={item === selectedItem}
          />
        )
      }
    />
  );
};

const AppKBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [term, setTerm] = React.useState('');
  const handleSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => setTerm(e.target.value);

  const debouncedTrackCustomGAEvent = debounce(searchTerm => {
    trackCustomGAEvent(GAEventCategory.SEARCH_BAR, GAEventAction.TYPE_QUERY, searchTerm);
  }, 500);

  React.useEffect(() => {
    debouncedTrackCustomGAEvent(term);
    return () => debouncedTrackCustomGAEvent.cancel();
  }, [term]);

  return (
    <KBarPortal>
      <KBarPositioner style={{ zIndex: 100 }}>
        <KBarAnimator className={classes.animator}>
          <KBarSearch
            onChange={handleSearchTerm}
            defaultPlaceholder={t('common:typeCommandOrSearch')}
            className={classes.search}
          />
          <RenderResults classes={classes} />
        </KBarAnimator>
      </KBarPositioner>
    </KBarPortal>
  );
};
export default AppKBar;
