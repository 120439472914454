import { USERS_AND_REPORTING_SERVICE_URL } from 'api/constants';
import { api } from 'redux/api/baseApi';
import { handleRtkQueryError } from 'redux/api/utils';
import { UserJSON } from 'redux/employees/types';
import { setUser } from './userSlice';

const userApi = api.enhanceEndpoints({ addTagTypes: ['User'] }).injectEndpoints({
  endpoints: build => ({
    getAuthenticatedUser: build.query<UserJSON, void>({
      query: () => {
        return {
          url: `${USERS_AND_REPORTING_SERVICE_URL}/users/me`,
          method: 'GET',
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (err) {
          handleRtkQueryError(err, dispatch);
        }
      },
    }),
    updateUser: build.mutation<UserJSON, Partial<UserJSON>>({
      query: payload => {
        const { id, ...rest } = payload;
        return {
          url: `${USERS_AND_REPORTING_SERVICE_URL}/users/${id}`,
          method: 'PATCH',
          body: rest,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetAuthenticatedUserQuery, useUpdateUserMutation } = userApi;
