import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'redux/store';
import { combineReducers } from 'redux';
import { InventoryResponse } from 'redux/inventory/apiTypes';
import { removeObjectFromArray, updateObjectInArray } from 'redux/utils';
import { CountedInventory, SalesOrder, SalesOrderProduct, CompletedSalesOrderInventory } from 'types/salesOrders';

type InitialOrderState = SalesOrder | null;
const activeOrderInitialState = null as InitialOrderState;

const activeOrder = createSlice({
  name: 'activeOrder',
  initialState: activeOrderInitialState,
  reducers: {
    setActiveSalesOrder: (_, { payload }: PayloadAction<SalesOrder | null>) => payload,
    updateActiveSalesOrder: (state, { payload }: PayloadAction<Partial<SalesOrder>>) => {
      if (state) {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
});
export const { setActiveSalesOrder, updateActiveSalesOrder } = activeOrder.actions;

type InitialReservedInventoryState = InventoryResponse[] | null;
const reservedInventoryState = null as InitialReservedInventoryState;

const reservedInventory = createSlice({
  name: 'reservedInventory',
  initialState: reservedInventoryState,
  reducers: {
    setReservedInventory: (_state, { payload }: PayloadAction<InventoryResponse[] | null>) => payload,
  },
});
export const { setReservedInventory } = reservedInventory.actions;

type ProductsInitialState = SalesOrderProduct[] | null;
const productsInitialState = null as ProductsInitialState;

const products = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setProducts: (_state, { payload }: PayloadAction<SalesOrderProduct[] | null>) => payload,
    addProduct: (state, { payload }: PayloadAction<SalesOrderProduct>) => {
      if (!state) {
        return;
      }
      state.push(payload);
    },
    setProductsCountedInventory: (state, { payload }: PayloadAction<CountedInventory[]>) =>
      state?.map(item => {
        return {
          ...item,
          counted_inventory: payload.find(p => p['product_id'] === item.id) ?? null,
        };
      }),
    updateProduct: (state, { payload }: PayloadAction<SalesOrderProduct>) => updateObjectInArray(state, { payload }),
    deleteProduct: (state, { payload }: PayloadAction<{ id: number }>) => removeObjectFromArray(state, { payload }),
  },
});
export const { setProducts, addProduct, setProductsCountedInventory, updateProduct, deleteProduct } = products.actions;

type InitialActiveIdState = string | null;
const activeId = createSlice({
  name: 'activeId',
  initialState: null as InitialActiveIdState,
  reducers: {
    setActiveSaleOrderId: (_, { payload }: PayloadAction<string | null>) => payload,
  },
});
export const { setActiveSaleOrderId } = activeId.actions;

const completedSalesProducts = createSlice({
  name: 'completedSalesProducts',
  initialState: null as CompletedSalesOrderInventory[] | null,
  reducers: {
    setCompletedSalesProducts: (_state, { payload }: PayloadAction<CompletedSalesOrderInventory[] | null>) => payload,
  },
});

export const { setCompletedSalesProducts } = completedSalesProducts.actions;
export const selectCompletedSalesProducts = (state: State) => state.saleOrders.completedSalesProducts;

export default combineReducers({
  activeId: activeId.reducer,
  activeOrder: activeOrder.reducer,
  products: products.reducer,
  reservedInventory: reservedInventory.reducer,
  completedSalesProducts: completedSalesProducts.reducer,
});
