// Default forecasting table hidden columns
// They can be overridden from DB using client settings
export const forecastingTablesVisibility = {
  forecastingDashboardPendingPurchaseOrders: {
    columns: [
      {
        field: 'products_count',
        hidden: true,
      },
      {
        field: 'estimated_price',
        hidden: true,
      },
      {
        field: 'exported_to_accounting',
        hidden: true,
      },
      {
        field: 'completed_at',
        hidden: true,
      },
      {
        field: 'task_chain_name',
        hidden: true,
      },
    ],
  },
  forecastingDashboardLatePurchaseOrders: {
    columns: [
      {
        field: 'products_count',
        hidden: true,
      },
      {
        field: 'estimated_price',
        hidden: true,
      },
      {
        field: 'exported_to_accounting',
        hidden: true,
      },
      {
        field: 'completed_at',
        hidden: true,
      },
      {
        field: 'task_chain_name',
        hidden: true,
      },
    ],
  },
  forecastingDashboardContacts: {
    columns: [
      {
        field: 'email',
        hidden: true,
      },
      {
        field: 'phone',
        hidden: true,
      },
      {
        field: 'address',
        hidden: true,
      },
      {
        field: 'person_type',
        hidden: true,
      },
      {
        field: 'type',
        hidden: true,
      },
      {
        field: 'subtype',
        hidden: true,
      },
    ],
  },
  forecastingDashboardSku: {
    columns: [
      {
        field: 'shelf_life_in_days',
        hidden: true,
      },
      {
        field: 'purchase_uom_abbreviation',
        hidden: true,
      },
      {
        field: 'unit_cost',
        hidden: true,
      },
      {
        field: 'category_name',
        hidden: true,
      },
      {
        field: 'category_type',
        hidden: true,
      },
      {
        field: 'location_classes',
        hidden: true,
      },
      {
        field: 'package_count',
        hidden: true,
      },
    ],
  },
};
