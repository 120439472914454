import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import i18n from './i18n';
import Routes from './Routes';
import MuiTheme from './theme';
import './assets/scss/index.scss';
import moment from 'moment';
import 'moment/locale/lt';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import store from 'redux/store';
import { history } from './redux/history';
moment.locale('lt');

const App: React.FC = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={MuiTheme}>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18n}>
            <Routes />
          </I18nextProvider>
        </ConnectedRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default App;
