import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { history } from './history';
import thunk from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { api } from 'redux/api/baseApi';
import createRootReducer from './rootReducer';
import { setAutoFreeze } from 'immer';

setAutoFreeze(false);

export type State = ReturnType<typeof store.getState>;

const store = configureStore({
  reducer: createRootReducer(history),
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), api.middleware, routerMiddleware(history), thunk],
  devTools: process.env.NODE_ENV !== 'production',
});

// A utility used to enable refetchOnMount and refetchOnReconnect behaviors
setupListeners(store.dispatch);

export default store;
