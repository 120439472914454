import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contact } from './apiTypes';
import { ContactRule } from './types';
import { updateObjectInArray, removeObjectFromArray } from 'redux/utils';

type InitialContactState = Contact | null;
type InitialActiveIdState = string | null;

const activeContactState = null as InitialContactState;
const activeContactIdState = null as InitialActiveIdState;

const activeContact = createSlice({
  name: 'activeContact',
  initialState: activeContactState,
  reducers: {
    setActiveContact: (_state, { payload }: PayloadAction<Contact | null>) => payload,
  },
});

export const { setActiveContact } = activeContact.actions;

const activeId = createSlice({
  name: 'activeId',
  initialState: activeContactIdState,
  reducers: {
    setActiveContactId: (_state, { payload }: PayloadAction<string | null>) => payload,
  },
});

export const { setActiveContactId } = activeId.actions;

type InitialContactRulesState = ContactRule[] | null;
const contactRulesInitialState = null as InitialContactRulesState;

const contactRules = createSlice({
  name: 'contactRules',
  initialState: contactRulesInitialState,
  reducers: {
    setContactRules: (_state, { payload }: PayloadAction<ContactRule[] | null>) => payload,
    addContactRule: (state, { payload }) => (state?.length ? [...state, payload] : [payload]),
    updateContactRule: (state, { payload }: PayloadAction<ContactRule>) => updateObjectInArray(state, { payload }),
    deleteContactRule: (state, { payload }: PayloadAction<{ id: number }>) => removeObjectFromArray(state, { payload }),
  },
});

export const { setContactRules, addContactRule, updateContactRule, deleteContactRule } = contactRules.actions;

export default combineReducers({
  contactRules: contactRules.reducer,
  activeId: activeId.reducer,
  activeContact: activeContact.reducer,
});
