import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateObjectInArray, removeObjectFromArray } from 'redux/utils';
import { CompletedPurchaseOrderProduct, PurchaseOrder, PurchaseOrderProduct } from 'types/purchaseOrders';
import { State } from 'redux/store';

type InitialOrderState = PurchaseOrder | null;
const activeOrderInitialState = null as InitialOrderState;

const activeOrder = createSlice({
  name: 'activeOrder',
  initialState: activeOrderInitialState,
  reducers: {
    setActivePurchaseOrder: (_, { payload }: PayloadAction<PurchaseOrder | null>) => payload,
    updateActivePurchaseOrder: (state, { payload }: PayloadAction<Partial<PurchaseOrder>>) => {
      if (state) {
        return {
          ...state,
          ...payload,
        };
      }
    },
  },
});

export const { setActivePurchaseOrder, updateActivePurchaseOrder } = activeOrder.actions;

type InitialPOProductsState = PurchaseOrderProduct[] | null;
const productsInitialState = null as InitialPOProductsState;

const products = createSlice({
  name: 'products',
  initialState: productsInitialState,
  reducers: {
    setPoProducts: (_state, { payload }: PayloadAction<PurchaseOrderProduct[] | null>) => payload,
    addPoProduct: (state, { payload }: PayloadAction<PurchaseOrderProduct>) => {
      if (!state) {
        return;
      }
      state.push(payload);
    },
    updatePoProduct: (state, { payload }: PayloadAction<PurchaseOrderProduct>) =>
      updateObjectInArray(state, { payload }),
    deletePoProduct: (state, { payload }: PayloadAction<{ id: number }>) => removeObjectFromArray(state, { payload }),
  },
});

export const { setPoProducts, addPoProduct, updatePoProduct, deletePoProduct } = products.actions;

const completedPurchaseProducts = createSlice({
  name: 'completedPurchaseProducts',
  initialState: null as CompletedPurchaseOrderProduct[] | null,
  reducers: {
    setCompletedPurchaseProducts: (_state, { payload }: PayloadAction<CompletedPurchaseOrderProduct[] | null>) =>
      payload,
  },
});

export const { setCompletedPurchaseProducts } = completedPurchaseProducts.actions;
export const selectCompletedPurchaseProducts = (state: State) => state.purchaseOrders.completedPurchaseProducts;

type InitialActiveIdState = string | null;
const activeIdState = null as InitialActiveIdState;

const activeId = createSlice({
  name: 'activeId',
  initialState: activeIdState,
  reducers: {
    setActivePurchaseOrderId: (_, { payload }: PayloadAction<string | null>) => payload,
  },
});

export const { setActivePurchaseOrderId } = activeId.actions;

export default combineReducers({
  activeId: activeId.reducer,
  activeOrder: activeOrder.reducer,
  products: products.reducer,
  completedPurchaseProducts: completedPurchaseProducts.reducer,
});
