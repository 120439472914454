import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { makeStyles, withStyles } from '@mui/styles';
import React from 'react';

const DarkerFormControl = withStyles({
  root: {
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
  },
})(FormControl);

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 130,
    width: '100%',
  },
}));

interface Option {
  value: string;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
}

export interface Props {
  values: Option[];
  value: string | null;
  onChange?: ((event: SelectChangeEvent<string | null>, child: React.ReactNode) => void) | undefined;
  inputLabel: string;
  disabled?: boolean;
  error?: any;
  helperText?: string;
  required?: boolean;
  name?: string;
  size?: 'small' | 'medium';
}

const DropdownSelect = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      values,
      value = '',
      onChange,
      inputLabel,
      disabled = false,
      error = null,
      helperText = null,
      required = false,
      name = '',
      size = 'small',
    },
    ref
  ) => {
    const classes = useStyles();
    return (
      <DarkerFormControl
        variant="outlined"
        className={classes.formControl}
        disabled={disabled}
        error={error}
        required={required}
        data-test={name}
        size={size}
        ref={ref}
      >
        <InputLabel>{inputLabel}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          label={inputLabel}
          renderValue={selectedValue =>
            values.find(option => option.value === selectedValue && !option.hidden)?.label || 'Not Available'
          }
        >
          {values
            .filter(option => !option.hidden)
            .map(option => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled || false}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </DarkerFormControl>
    );
  }
);

export default DropdownSelect;
