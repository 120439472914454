import { createSlice } from '@reduxjs/toolkit';

export interface FilterValue {
  dirty: string | null;
  active: string | null;
}

type InitialState = {
  [key: string]: FilterValue;
};

export const START_DATE_KEY = 'order_creation_date[from]';
export const END_DATE_KEY = 'order_creation_date[to]';

const startOfMonth = null;
const endOfMonth = null;
const initialState: InitialState = {
  [START_DATE_KEY]: { dirty: startOfMonth, active: startOfMonth },
  [END_DATE_KEY]: { dirty: endOfMonth, active: endOfMonth },
};

const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setDirtyFilter: (state, action) => ({
      ...state,
      [action.payload.key]: {
        dirty: action.payload.value,
        active: state[action.payload.key].active,
      },
    }),
    setActiveFilter: (state, action) => ({
      ...state,
      [action.payload.key]: {
        dirty: state[action.payload.key].dirty,
        active: state[action.payload.key].dirty,
      },
    }),
  },
});

export const { setDirtyFilter, setActiveFilter } = filters.actions;

export default filters.reducer;
