import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(_theme => ({
  dialogTitle: {
    maxWidth: 300,
    alignSelf: 'center',
    textAlign: 'center',
  },
  dialogContent: {
    maxWidth: 300,
    alignSelf: 'center',
    textAlign: 'center',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
