import { RequestMethod, fetchAxiosAPI } from 'api';
import { FORECASTING_SERVICE_URL, WMS_API_SERVICE } from './constants';
import { DataExportParams, MIMEType } from 'components/mrtTable/utils';

const ENDPOINTS = {
  replenishments: `${FORECASTING_SERVICE_URL}/replenishments`,
  analytics: `${FORECASTING_SERVICE_URL}/analytics`,
  skus: `${WMS_API_SERVICE}/skus`,
  promotions: `${WMS_API_SERVICE}/promotions`,
  consumablesDemand: `${WMS_API_SERVICE}/inventory/consumables`,
} as const;

export type EndpointKeys = keyof typeof ENDPOINTS;

export const exportToFile = async (
  endpointKey: EndpointKeys,
  mimeType: MIMEType,
  dataToExportParams: DataExportParams
) => {
  const endpointUrl = ENDPOINTS[endpointKey];
  return fetchAxiosAPI(`${endpointUrl}/export`, RequestMethod.POST, dataToExportParams, {
    headers: {
      Accept: mimeType,
    },
    responseType: 'arraybuffer',
  });
};
