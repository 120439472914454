import { State } from 'redux/store';
import RouteWithLayout from 'components/RouteWithLayout';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { fetchAndSetSettings, setInitialLocalSettings } from 'redux/settings/thunks';
import Spinner from 'Spinner';
import { Main as MainLayout } from './layouts';
import AppKBarProvider from 'components/AppKBar/AppKBarProvider';
import AppKBar from 'components/AppKBar/AppKBar';
import { SearchButtonProvider } from 'components/AppKBar/SearchButtonContext';
import { initializeGoogleAnalytics } from 'utils/googleAnalytics';
import { RoutePaths } from 'constants/routePaths';
import { useLazyGetAuthenticatedUserQuery } from 'redux/user/userApi';
import { selectUserGroupName } from 'redux/user/userSlice';

const DashboardScreen = lazy(() => import('views/Dashboard'));
const ReplenishmentsValidationScreen = lazy(() => import('views/ReplenishmentsValidation'));
const NotFoundScreen = lazy(() => import('views/NotFound'));
const LocationsScreen = lazy(() => import('modules/locations/warehouseMap'));
const InventoryScreen = lazy(() => import('modules/inventory'));
const InventoryConsumablesScreen = lazy(() => import('modules/inventoryConsumables'));
const PalletsScreen = lazy(() => import('modules/pallets'));
const SkusCatalogueScreen = lazy(() => import('modules/skus/Skus'));
const SkuFormScreen = lazy(() => import('modules/skus/SkuForm'));
const PurchaseOrdersScreen = lazy(() => import('modules/purchaseOrders/PurchaseOrders'));
const ManufacturingOrdersScreen = lazy(() => import('modules/manufacturingOrders/ManufacturingOrders'));
const MOFormScreen = lazy(() => import('modules/manufacturingOrders/form'));
const SaleOrdersScreen = lazy(() => import('modules/salesOrders/SalesOrders'));
const SOFormScreen = lazy(() => import('modules/salesOrders/form'));
const InternalOperationOrdersScreen = lazy(() => import('modules/internalOperations/InternalOperations'));
const IPFormScreen = lazy(() => import('modules/internalOperations/form'));
const ReturnOrdersScreen = lazy(() => import('modules/returnOrders/ReturnOrders'));
const ReturnOrdersFormScreen = lazy(() => import('modules/returnOrders/form'));
const LocationsFormScreen = lazy(() => import('modules/locations/locationsForm'));
const POFormScreen = lazy(() => import('modules/purchaseOrders/form'));
const TasksScreen = lazy(() => import('modules/tasks/WarehouseTasks'));
const ContactsScreen = lazy(() => import('modules/contacts/Contacts'));
const ContactFormScreen = lazy(() => import('modules/contacts/form'));
const UomsScreen = lazy(() => import('modules/uoms/Uoms'));
const UomFormScreen = lazy(() => import('modules/uoms/form'));
const UomCategoriesScreen = lazy(() => import('modules/uomCategories/UomCategories'));
const UomCategoryFormScreen = lazy(() => import('modules/uomCategories/form'));
const SkuCategoriesScreen = lazy(() => import('modules/skuCategories/SkuCategories'));
const SkuCategoryFormScreen = lazy(() => import('modules/skuCategories/form'));
const ActionsLogScreen = lazy(() => import('modules/actionsLog/ActionsLogTable'));
const LocationClassesForm = lazy(() => import('modules/locationClasses/locationClassesForm'));
const LocationClasses = lazy(() => import('modules/locationClasses'));
const EmployeesScreen = lazy(() => import('modules/employee/Employees'));
const EmployeesFormScreen = lazy(() => import('modules/employee/form'));
const RequestResponseLogScreen = lazy(() => import('modules/requestResponseLogs/RequestResponseLog'));
const GenerateReportsScreen = lazy(() => import('modules/generateReports'));
const SettingsScreen = lazy(() => import('modules/settings/Settings'));
const BaseSettingsScreen = lazy(() => import('modules/settings/baseSettings/BaseSettings'));
const OrdersSettingsScreen = lazy(() => import('modules/settings/ordersSettings/OrdersSettings'));
const MOSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/MOSettingsForm'));
const SOSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/SOSettingsForm'));
const POSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/POSettingsForm'));
const ReturnSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/ReturnSettingsForm'));
const InBoundSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/InBoundSettingsForm'));
const OutBoundSettingsFormScreen = lazy(() => import('modules/settings/SettingsForms/OutBoundSettingsForm'));
const SkuSalesPromotionsScreen = lazy(() => import('modules/skuSalesPromotions'));
const PurchaseOrdersDashboard = lazy(() => import('modules/purchaseOrders/dashboard'));
const InventoryForecasts = lazy(() => import('modules/inventoryForecast/forecasting'));
const InventoryReplenishments = lazy(() => import('modules/inventoryForecast/replenishments'));
const InventoryAnalytics = lazy(() => import('modules/inventoryForecast/analytics'));
const WarehousesScreen = lazy(() => import('modules/warehouses'));
const WarehousesFormScreen = lazy(() => import('modules/warehouses/form'));
const ReceivingTasksScreen = lazy(() => import('modules/webTasks/receiving/ReceivingTasks'));
const ReceivingWizardScreen = lazy(() => import('modules/webTasks/receiving/ReceivingWizard'));
const PutawayTasksScreen = lazy(() => import('modules/webTasks/putaway/PutawayTasks'));
const PutawayWizardScreen = lazy(() => import('modules/webTasks/putaway/PutawayWizard'));
const InProgressTasksScreen = lazy(() => import('modules/webTasks/inProgress/InProgressTasks'));
const ManufacturingOrdersDisassembleScreen = lazy(() =>
  import('modules/manufacturingOrdersDisassemble/ManufacturingOrdersDisassemble')
);
const ManufacturingOrderDisassembleFormScreen = lazy(() => import('modules/manufacturingOrdersDisassemble/form'));

const CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const COGNITO_LOGIN_DOMAIN_URL = process.env.REACT_APP_COGNITO_URL;

const REDIRECT_URI = `${window.location.protocol}//${window.location.host}`;
const COGNITO_HOSTED_UI_URL = `${COGNITO_LOGIN_DOMAIN_URL}/login?client_id=${CLIENT_ID}&response_type=token&scope=email+openid+profile&redirect_uri=${REDIRECT_URI}`;

export const NOT_FOUND = '/not-found';
const Routes = () => {
  const [getAuthenticatedUser] = useLazyGetAuthenticatedUserQuery();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.hash);
  const id_token = urlParams.get('#id_token');
  const access_token = urlParams.get('access_token');
  const expires_in = urlParams.get('expires_in');
  const token_type = urlParams.get('token_type');

  if (access_token) {
    localStorage.setItem('access_token', access_token);
  }
  if (id_token) {
    localStorage.setItem('id_token', id_token);
  }
  if (expires_in) {
    localStorage.setItem('expires_in', expires_in);
  }
  if (token_type) {
    localStorage.setItem('token_type', token_type);
  }

  const fetchSettings = async () => {
    setLoading(true);
    await getAuthenticatedUser();
    await dispatch(fetchAndSetSettings());
    await dispatch(setInitialLocalSettings());
    setLoading(false);
  };

  const userGroupName = useSelector(selectUserGroupName);
  const localSettings = useSelector((state: State) => state.settings.localSettings);

  const getHomeScreen = () => {
    if (localSettings.svsModuleChecked && userGroupName !== 'product_manager') {
      return <Redirect exact from="/" to={RoutePaths.Dashboard} />;
    }

    if (localSettings.forecastingModuleChecked) {
      return <Redirect exact from="/" to={RoutePaths.PurchaseDashboard} />;
    }

    return <Redirect exact from="/" to={RoutePaths.BaseSettings} />;
  };

  useEffect(() => {
    fetchSettings();
  }, [dispatch]);

  useEffect(() => {
    // Not set yet
    if (userGroupName === null) {
      return;
    }

    // Access rights not sufficient
    if (userGroupName === 'EmployeeDeviceOnly') {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      localStorage.removeItem('token_bearer');
      localStorage.removeItem('expires_in');
      history.push(RoutePaths.Login);
    }
  }, [userGroupName]);

  React.useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      {loading ? (
        <Spinner />
      ) : (
        <SearchButtonProvider>
          <AppKBarProvider>
            <AppKBar />
            <Switch>
              {getHomeScreen()}
              <RouteWithLayout
                component={DashboardScreen}
                exact
                layout={MainLayout}
                id="dashboard"
                path={RoutePaths.Dashboard}
                protected
              />
              <RouteWithLayout
                component={ReplenishmentsValidationScreen}
                exact
                layout={MainLayout}
                id="replenishments-validation"
                path={RoutePaths.ReplenishmentsValidation}
                protected
              />
              <RouteWithLayout
                component={InventoryForecasts}
                exact
                layout={MainLayout}
                path={RoutePaths.InventoryForecasts}
                id="inventory-forecast-forecasts"
              />
              <RouteWithLayout
                component={InventoryReplenishments}
                exact
                layout={MainLayout}
                path={RoutePaths.InventoryReplenishments}
                id="inventory-forecast-replenishments"
              />
              <RouteWithLayout
                component={InventoryAnalytics}
                exact
                layout={MainLayout}
                path={RoutePaths.InventoryAnalytics}
                id="inventory-forecast-analytics"
              />
              <RouteWithLayout
                component={SkuSalesPromotionsScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Promotions}
                id="promotions"
              />
              <RouteWithLayout
                component={PurchaseOrdersDashboard}
                exact
                layout={MainLayout}
                id="purchase-dashboard"
                path={RoutePaths.PurchaseDashboard}
              />
              <RouteWithLayout
                component={LocationsScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Locations}
                id="locations"
              />
              <RouteWithLayout
                component={LocationsFormScreen}
                exact
                layout={MainLayout}
                id="locations-form"
                path={`${RoutePaths.LocationForm}/:id?`}
              />
              <RouteWithLayout
                component={InventoryScreen}
                exact
                layout={MainLayout}
                id="inventory"
                path={RoutePaths.Inventory}
              />
              <RouteWithLayout
                component={WarehousesScreen}
                exact
                layout={MainLayout}
                id="warehouses"
                path={RoutePaths.Warehouses}
              />
              <RouteWithLayout
                component={WarehousesFormScreen}
                exact
                layout={MainLayout}
                id="warehouse-form"
                path={`${RoutePaths.WarehouseForm}/:id?`}
              />
              <RouteWithLayout
                component={InventoryConsumablesScreen}
                exact
                layout={MainLayout}
                id="inventory-consumables"
                path={RoutePaths.InventoryConsumables}
              />
              <RouteWithLayout
                component={PalletsScreen}
                exact
                layout={MainLayout}
                id="pallets"
                path={RoutePaths.Pallets}
              />
              <RouteWithLayout
                component={LocationClasses}
                exact
                layout={MainLayout}
                id="location-classes"
                path={RoutePaths.LocationClasses}
              />
              <RouteWithLayout
                component={LocationClassesForm}
                exact
                layout={MainLayout}
                id="location-classes-form"
                path={`${RoutePaths.LocationClassForm}/:id?`}
              />
              <RouteWithLayout
                component={PurchaseOrdersScreen}
                exact
                layout={MainLayout}
                id="purchase-orders"
                path={RoutePaths.PurchaseOrders}
              />
              <RouteWithLayout
                component={POFormScreen}
                exact
                layout={MainLayout}
                id="purchase-order-form"
                path={`${RoutePaths.PurchaseOrderForm}/:id?`}
              />
              <RouteWithLayout
                component={SaleOrdersScreen}
                exact
                layout={MainLayout}
                id="sales-orders"
                path={RoutePaths.SalesOrders}
              />
              <RouteWithLayout
                component={SOFormScreen}
                exact
                layout={MainLayout}
                id="sales-order-form"
                path={`${RoutePaths.SalesOrderForm}/:id?`}
              />
              <RouteWithLayout
                component={InternalOperationOrdersScreen}
                exact
                layout={MainLayout}
                id="internal-operations"
                path={RoutePaths.InternalOperations}
              />
              <RouteWithLayout
                component={IPFormScreen}
                exact
                layout={MainLayout}
                id="internal-operation-form"
                path={`${RoutePaths.InternalOperationForm}/:id?`}
              />
              <RouteWithLayout
                component={ReturnOrdersScreen}
                exact
                layout={MainLayout}
                id="returns"
                path={RoutePaths.Returns}
              />
              <RouteWithLayout
                component={ReturnOrdersFormScreen}
                exact
                layout={MainLayout}
                id="returns-form"
                path={`${RoutePaths.ReturnForm}/:id?`}
              />
              <RouteWithLayout
                component={ManufacturingOrdersScreen}
                exact
                layout={MainLayout}
                id="manufacturing-orders"
                path={RoutePaths.ManufacturingOrders}
              />
              <RouteWithLayout
                component={MOFormScreen}
                exact
                layout={MainLayout}
                id="manufacturing-order-form"
                path={`${RoutePaths.ManufacturingOrderForm}/:id?`}
              />
              <RouteWithLayout
                component={ManufacturingOrdersDisassembleScreen}
                exact
                layout={MainLayout}
                id="manufacturing-orders-disassemble"
                path={RoutePaths.ManufacturingOrdersDisassemble}
              />
              <RouteWithLayout
                component={ManufacturingOrderDisassembleFormScreen}
                exact
                layout={MainLayout}
                id="manufacturing-order-disassemble-form"
                path={`${RoutePaths.ManufacturingOrderDisassembleForm}/:id?`}
              />
              <RouteWithLayout
                component={SkusCatalogueScreen}
                exact
                layout={MainLayout}
                id="catalogue"
                path={RoutePaths.SkuCatalogue}
              />
              <RouteWithLayout
                component={SkuFormScreen}
                exact
                layout={MainLayout}
                id="catalogue-form"
                path={`${RoutePaths.SkuForm}/:id?`}
              />
              <RouteWithLayout
                component={SkuCategoriesScreen}
                exact
                layout={MainLayout}
                id="sku-categories"
                path={RoutePaths.SkuCategories}
              />
              <RouteWithLayout
                component={SkuCategoryFormScreen}
                exact
                layout={MainLayout}
                id="sku-category-form"
                path={`${RoutePaths.SkuCategoryForm}/:id?`}
              />
              <RouteWithLayout component={UomsScreen} exact layout={MainLayout} path={RoutePaths.Uoms} id="uoms" />
              <RouteWithLayout
                component={UomFormScreen}
                exact
                layout={MainLayout}
                id="uom-form"
                path={`${RoutePaths.UomForm}/:id?`}
              />
              <RouteWithLayout
                component={UomCategoriesScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.UomCategories}
                id="uom-categories"
              />
              <RouteWithLayout
                component={UomCategoryFormScreen}
                exact
                layout={MainLayout}
                id="uom-category-form"
                path={`${RoutePaths.UomCategoryForm}/:id?`}
              />
              <RouteWithLayout
                component={GenerateReportsScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Reports}
                id="reports"
              />

              <RouteWithLayout
                component={ContactsScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Contacts}
                id="contacts"
              />
              <RouteWithLayout
                component={ContactFormScreen}
                exact
                layout={MainLayout}
                id="contact-form"
                path={`${RoutePaths.ContactForm}/:id?`}
              />
              <RouteWithLayout component={TasksScreen} exact layout={MainLayout} path={RoutePaths.Tasks} id="tasks" />
              <RouteWithLayout
                component={EmployeesScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Employees}
                id="employees"
              />
              <RouteWithLayout
                component={EmployeesFormScreen}
                exact
                layout={MainLayout}
                id="employee-form"
                path={`${RoutePaths.EmployeeForm}/:id?`}
              />
              <RouteWithLayout
                component={ActionsLogScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.ActionLogs}
                id="action-logs"
              />
              <RouteWithLayout
                component={RequestResponseLogScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.RequestResponseLog}
                id="request-response-log"
              />
              <RouteWithLayout
                component={SettingsScreen}
                exact
                layout={MainLayout}
                path={RoutePaths.Settings}
                id="settings"
              />
              <RouteWithLayout
                component={BaseSettingsScreen}
                exact
                layout={MainLayout}
                id="base-settings"
                path={RoutePaths.BaseSettings}
              />
              <RouteWithLayout
                component={OrdersSettingsScreen}
                exact
                layout={MainLayout}
                id="orders-settings"
                path={RoutePaths.OrderSettings}
              />

              <RouteWithLayout
                component={MOSettingsFormScreen}
                exact
                layout={MainLayout}
                id="manufacturing-settings-form"
                path={`${RoutePaths.ManufacturingSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={SOSettingsFormScreen}
                exact
                layout={MainLayout}
                id="sales-settings-form"
                path={`${RoutePaths.SalesSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={POSettingsFormScreen}
                exact
                layout={MainLayout}
                id="purchase-settings-form"
                path={`${RoutePaths.PurchaseSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={ReturnSettingsFormScreen}
                exact
                layout={MainLayout}
                id="returns-settings-form"
                path={`${RoutePaths.ReturnsSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={OutBoundSettingsFormScreen}
                exact
                layout={MainLayout}
                id="internal-operations-outbound-settings-form"
                path={`${RoutePaths.InternalOpsOutboundSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={InBoundSettingsFormScreen}
                exact
                layout={MainLayout}
                id="internal-operations-inbound-settings-form"
                path={`${RoutePaths.InternalOpsInboundSettingsForm}/:id?`}
              />
              <RouteWithLayout
                component={ReceivingTasksScreen}
                exact
                layout={MainLayout}
                id="receiving-tasks"
                path={RoutePaths.ReceivingTasks}
              />
              <RouteWithLayout
                component={ReceivingWizardScreen}
                exact
                layout={MainLayout}
                id="receiving-task-wizard"
                path={`${RoutePaths.ReceivingTaskWizard}/:id?`}
              />
              <RouteWithLayout
                component={PutawayTasksScreen}
                exact
                layout={MainLayout}
                id="putaway-tasks"
                path={RoutePaths.PutawayTasks}
              />
              <RouteWithLayout
                component={PutawayWizardScreen}
                exact
                layout={MainLayout}
                id="putaway-task-wizard"
                path={`${RoutePaths.PutawayTaskWizard}/:id?`}
              />
              <RouteWithLayout
                component={InProgressTasksScreen}
                exact
                layout={MainLayout}
                id="in-progress-tasks"
                path={RoutePaths.InProgressTasks}
              />
              <Route
                path={RoutePaths.Login}
                component={() => {
                  window.location.href = COGNITO_HOSTED_UI_URL;
                  return null;
                }}
              />
              <RouteWithLayout component={NotFoundScreen} layout={MainLayout} path="*" protected={false} />
            </Switch>
          </AppKBarProvider>
        </SearchButtonProvider>
      )}
    </Suspense>
  );
};

export default Routes;
