import { AccessGroupName } from 'redux/employees/types';

interface Column {
  field: string;
  title?: string;
  hidden?: boolean;
}

interface TableSettings {
  hidden?: boolean;
  columns?: Column[];
}

interface FormSettings {
  invisibleFields?: Record<string, boolean>;
  reviewingActionEnabled?: boolean;
}

interface SidebarPagesTitle {
  id: string;
  title: string;
}

export interface SettingsJSON {
  isSVSModuleEnabled: boolean;
  isForecastingModuleEnabled: boolean;
  isChangePriceButtonDisabled: boolean;
  isLocationExportDisabled: boolean;
  tables: Record<string, TableSettings> | null;
  forms: Record<string, FormSettings> | null;
  disabledRoutes: string[];
  sidebarPagesTitles: SidebarPagesTitle[];
  // TODO: automated po
  isAutomatedPOEnabled: boolean;
  isManualPOEnabled: boolean;
  isUsingOldIOForm: boolean;
}

export interface User {
  id: number | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  userGroup: AccessGroupName | null;
  name: string | null;
}

export enum SettingsType {
  FRONT_END = 'front_end',
  BACK_END = 'back_end',
  MOBILE_APP = 'mobile_app',
}

export interface LocalSettings {
  svsModuleChecked: boolean;
  forecastingModuleChecked: boolean;
  // TODO: automated po
  isAutomatedPOEnabled: boolean;
  isManualPOEnabled: boolean;
}

export enum LocalSettingsTypes {
  SVS_MODULE_CHECKED = 'svsModuleChecked',
  FORECASTING_MODULE_CHECKED = 'forecastingModuleChecked',
  // TODO: automated po
  AUTOMATED_PO = 'isAutomatedPOEnabled',
  MANUAL_PO = 'isManualPOEnabled',
}
