import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  AggregatedCompletedSales,
  AggregatedForecastedSales,
  AggregatedSkuSalesPromotions,
  AggregationIntervals,
  ProcessedForecastedSales,
  SkuInventoryBalance,
  SkuInventoryPurchaseOrderProduct,
  SkuSalesForecast,
} from './types';
import moment from 'moment';
import { dateRangeValues } from 'modules/forecast/utils';

type InitialActiveAggregatedForecastedSalesState = AggregatedForecastedSales[] | [];
const activeAggregatedForecastedSalesState = [] as InitialActiveAggregatedForecastedSalesState;

const activeAggregatedForecastedSales = createSlice({
  name: 'activeAggregatedForecastedSales',
  initialState: activeAggregatedForecastedSalesState,
  reducers: {
    setActiveAggregatedForecastedSales: (_state, { payload }) => payload,
  },
});
export const { setActiveAggregatedForecastedSales } = activeAggregatedForecastedSales.actions;

type InitialActiveAggregatedCompletedSalesState = AggregatedCompletedSales[] | [];
const activeAggregatedCompletedSalesState = [] as InitialActiveAggregatedCompletedSalesState;

const activeAggregatedCompletedSales = createSlice({
  name: 'activeAggregatedCompletedSales',
  initialState: activeAggregatedCompletedSalesState,
  reducers: {
    setActiveAggregatedCompletedSales: (_state, { payload }) => payload,
  },
});
export const { setActiveAggregatedCompletedSales } = activeAggregatedCompletedSales.actions;

type InitialActiveSkuSalesForecastState =
  | (Pick<SkuSalesForecast, 'id' | 'sku_name'> & { table_product_id?: number })
  | null;
const activeSkuSalesForecastInitialState = null as InitialActiveSkuSalesForecastState;

const activeSkuSalesForecast = createSlice({
  name: 'activeSkuSalesForecast',
  initialState: activeSkuSalesForecastInitialState,
  reducers: {
    setActiveSkuSalesForecast: (_, { payload }) => payload,
  },
});
export const { setActiveSkuSalesForecast } = activeSkuSalesForecast.actions;

type InitialActiveAggregatedSkuInventoryBalanceState = SkuInventoryBalance[] | null;
const activeAggregatedSkuInventoryBalanceInitialState = null as InitialActiveAggregatedSkuInventoryBalanceState;

const activeAggregatedSkuInventoryBalance = createSlice({
  name: 'activeAggregatedSkuInventoryBalance',
  initialState: activeAggregatedSkuInventoryBalanceInitialState,
  reducers: {
    setActiveAggregatedSkuInventoryBalance: (_, { payload }) => payload,
  },
});
export const { setActiveAggregatedSkuInventoryBalance } = activeAggregatedSkuInventoryBalance.actions;

type InitialActiveAggregatedSkuInventoryPurchaseOrderProductsState = SkuInventoryPurchaseOrderProduct[] | null;
const activeAggregatedSkuInventoryPurchaseOrderProductsInitialState = null as InitialActiveAggregatedSkuInventoryPurchaseOrderProductsState;

const activeAggregatedSkuInventoryPurchaseOrderProducts = createSlice({
  name: 'activeAggregatedSkuInventoryPurchaseOrderProducts',
  initialState: activeAggregatedSkuInventoryPurchaseOrderProductsInitialState,
  reducers: {
    setActiveAggregatedSkuInventoryPurchaseOrderProducts: (_, { payload }) => payload,
  },
});

export const {
  setActiveAggregatedSkuInventoryPurchaseOrderProducts,
} = activeAggregatedSkuInventoryPurchaseOrderProducts.actions;

type InitialActiveProcessedForecastedSalesState = ProcessedForecastedSales[] | null;
const activeProcessedForecastedSalesState = null as InitialActiveProcessedForecastedSalesState;

const activeProcessedForecastedSales = createSlice({
  name: 'activeProcessedForecastedSales',
  initialState: activeProcessedForecastedSalesState,
  reducers: {
    setActiveProcessedForecastedSales: (_, { payload }) => payload,
  },
});

export const { setActiveProcessedForecastedSales } = activeProcessedForecastedSales.actions;

type InitialActiveAggregatedSkuSalesPromotionsState = AggregatedSkuSalesPromotions[] | null;
const activeAggregatedSkuSalesPromotionsState = null as InitialActiveAggregatedSkuSalesPromotionsState;

const activeAggregatedSkuSalesPromotions = createSlice({
  name: 'activeAggregatedSkuSalesPromotions',
  initialState: activeAggregatedSkuSalesPromotionsState,
  reducers: {
    setActiveAggregatedSkuSalesPromotions: (_, { payload }) => payload,
  },
});

export const { setActiveAggregatedSkuSalesPromotions } = activeAggregatedSkuSalesPromotions.actions;

const activeInitialActiveAggregationIntervalState = AggregationIntervals.Default;

const activeAggregationInterval = createSlice({
  name: 'activeAggregationInterval',
  initialState: activeInitialActiveAggregationIntervalState,
  reducers: {
    setActiveAggregationInterval: (_, { payload }) => payload,
  },
});

export const { setActiveAggregationInterval } = activeAggregationInterval.actions;

export interface ActiveDatesRangeState {
  from: string;
  to: string;
}

export const activeDatesRangeInitialState: ActiveDatesRangeState = {
  from:
    dateRangeValues.find(v => v.isDefault)?.value ||
    moment()
      .subtract(4, 'months')
      .format('YYYY-MM-DD'),
  to: moment()
    .add(4, 'months')
    .format('YYYY-MM-DD'),
};

const activeDatesRange = createSlice({
  name: 'activeDatesRange',
  initialState: activeDatesRangeInitialState,
  reducers: {
    setActiveDatesRange: (_, { payload }: PayloadAction<ActiveDatesRangeState>) => payload,
  },
});

export const { setActiveDatesRange } = activeDatesRange.actions;

export default combineReducers({
  activeAggregatedForecastedSales: activeAggregatedForecastedSales.reducer,
  activeAggregatedCompletedSales: activeAggregatedCompletedSales.reducer,
  activeSkuSalesForecast: activeSkuSalesForecast.reducer,
  activeAggregatedSkuInventoryBalance: activeAggregatedSkuInventoryBalance.reducer,
  activeAggregatedSkuInventoryPurchaseOrderProducts: activeAggregatedSkuInventoryPurchaseOrderProducts.reducer,
  activeProcessedForecastedSales: activeProcessedForecastedSales.reducer,
  activeAggregationInterval: activeAggregationInterval.reducer,
  activeDatesRange: activeDatesRange.reducer,
  activeAggregatedSkuSalesPromotions: activeAggregatedSkuSalesPromotions.reducer,
});
