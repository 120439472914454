import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import analytics from './analytics/reducers';
import contacts from './contacts/reducers';
import filters from './filters/reducers';
import internalOperations from './internalOperationOrders/reducers';
import inventory from './inventory/reducers';
import locationClasses from './locationClasses/reducers';
import location from './locations/reducers';
import manufacturingOrders from './manufacturingOrders/reducers';
import forecasting from './forecasting/reducers';
import purchaseOrders from './purchaseOrders/reducers';
import returns from './returnOrders/reducers';
import saleOrders from './saleOrders/reducers';
import settings from './settings/reducers';
import skus from './skus/reducers';
import snackbar from './snackbar/reducers';
import table from './table/reducers';
import uoms from './uoms/reducers';
import visibility from './visibility/reducers';
import employees from './employees/reducers';
import ordersSettings from './ordersSettings/reducers';
import apiErrors from './apiErrors/reducers';
import loading from './loading/loadingSlice';
import navigation from './navigation/navigationSlice';
import authenticatedUser from './user/userSlice';
import webTasks from './webTasks/webTasksSlice';
import manufacturingOrdersDisassemble from './manufacturingOrdersDisassemble/reducers';
import { api } from './api/baseApi';

const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    location,
    inventory,
    skus,
    contacts,
    internalOperations,
    returns,
    purchaseOrders,
    manufacturingOrders,
    saleOrders,
    snackbar,
    visibility,
    filters,
    uoms,
    analytics,
    settings,
    locationClasses,
    table,
    employees,
    ordersSettings,
    apiErrors,
    forecasting,
    loading,
    navigation,
    authenticatedUser,
    webTasks,
    manufacturingOrdersDisassemble,
    [api.reducerPath]: api.reducer,
  });

export default createRootReducer;
