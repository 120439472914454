import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import MuiTypography from './overrides/MuiTypography';
import MuiTableRow from './overrides/MuiTableRow';
import MuiTableHead from './overrides/MuiTableHead';
import MuiTableCell from './overrides/MuiTableCell';
import MuiPaper from './overrides/MuiPaper';
import MuiIconButton from './overrides/MuiIconButton';

const getMuiTheme = () =>
  createTheme({
    palette,
    typography,
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          ...MuiTypography,
        },
      },
      MuiTableRow: {
        styleOverrides: {
          ...MuiTableRow,
        },
      },
      MuiTableHead: {
        styleOverrides: {
          ...MuiTableHead,
        },
      },
      MuiTableCell: {
        styleOverrides: {
          ...MuiTableCell,
        },
      },
      MuiPaper: {
        styleOverrides: {
          ...MuiPaper,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          ...MuiIconButton,
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          selectLabel: {
            display: 'none',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            '&[disabled]': {
              color: palette.disabled,
              pointerEvents: 'none',
              textDecoration: 'none',
            },
          },
        },
      },
    },
  });

const theme = getMuiTheme();

export default theme;
