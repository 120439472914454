import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import i18n from 'i18n';
import { openSnackbar } from 'redux/snackbar/reducers';

export const handleRtkQueryError = (
  err: any,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryType: 'mutation' | 'query' = 'query'
) => {
  const { error } = err || {};
  if (
    (queryType === 'query' && error?.status === 404) ||
    (queryType === 'query' && err.status === 404) ||
    (queryType === 'query' && error?.originalStatus === 404)
  ) {
    return;
  }

  let message = i18n.t('common:Unexpected error');

  if (typeof error?.data === 'string') {
    try {
      const errorData = JSON.parse(error.data);
      const messages = errorData?.error?.messages;
      message = messages?.message?.[0] || (messages && JSON.stringify(messages)) || i18n.t('common:Unexpected error');
    } catch (parseError) {
      console.error('Error parsing error.data:', parseError);
    }
  } else {
    const messages = error?.data?.error?.messages;
    message = messages?.message?.[0] || (messages && JSON.stringify(messages)) || i18n.t('common:Unexpected error');
  }

  dispatch(openSnackbar({ message, severity: 'error' }));
};
