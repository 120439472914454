import palette from '../palette';

export default {
  root: {
    color: palette.text.primary,
    fontSize: '13px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
    borderBottom: `1px solid ${palette.divider}`,
  },
};
