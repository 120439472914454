import { Button, Collapse, colors, Divider, List, ListItem, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconExpandLess from '@mui/icons-material/ExpandLess';
import IconExpandMore from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation, matchPath } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  iconClosed: {
    marginRight: 0,
  },
  expansionIcon: {
    color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const doesRouteMatch = (currentPath, sidebarPath) => {
  const matchResult = matchPath(currentPath, { path: sidebarPath });
  return matchResult !== null;
};

const SidebarNavItem = ({ disabledRoutes, title, href, icon, id, items = [], sidebarOpen, onSidebarToggle }) => {
  const classes = useStyles();
  const location = useLocation();
  const isExpandable = items && items.length > 0;
  const [collapsed, setCollapsed] = React.useState(false);

  const isParentOfActiveItem = items.some(item => doesRouteMatch(location.pathname, item.href));

  React.useEffect(() => {
    if (isParentOfActiveItem && !collapsed) {
      setCollapsed(true);
    }
  }, [isParentOfActiveItem]);

  const handleClick = () => {
    setCollapsed(!collapsed);
  };
  const isDisabled = !!(disabledRoutes?.length && disabledRoutes.find(route => route === id));

  return (
    !isDisabled && (
      <>
        <ListItem data-test={id} className={classes.item} disableGutters key={title}>
          <Tooltip title={sidebarOpen ? '' : title} placement="right">
            <>
              {!href || typeof href !== 'string' ? (
                <Button className={classes.button} onClick={handleClick}>
                  <div
                    className={clsx(classes.icon, {
                      [classes.iconClosed]: !sidebarOpen,
                    })}
                  >
                    {icon}
                  </div>
                  {sidebarOpen && title}
                  {isExpandable && !collapsed && <IconExpandMore className={classes.expansionIcon} />}
                  {isExpandable && collapsed && <IconExpandLess className={classes.expansionIcon} />}
                </Button>
              ) : (
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  onClick={onSidebarToggle}
                  to={href}
                >
                  <div
                    className={clsx(classes.icon, {
                      [classes.iconClosed]: !sidebarOpen,
                    })}
                  >
                    {icon}
                  </div>
                  {sidebarOpen && title}
                </Button>
              )}
            </>
          </Tooltip>
        </ListItem>
        {isExpandable && (
          <Collapse in={collapsed} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
              {items.map(item => (
                <SidebarNavItem
                  {...item}
                  disabledRoutes={disabledRoutes}
                  key={item.title}
                  sidebarOpen={sidebarOpen}
                  onSidebarToggle={onSidebarToggle}
                />
              ))}
            </List>
            <Divider />
          </Collapse>
        )}
      </>
    )
  );
};

export default SidebarNavItem;
