import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import SnackbarMessage from 'components/snackbar/Container';
import ActionDialog from 'components/dialog/ActionDialog';

const useStyles = makeStyles(() => ({
  root: {},
}));

interface Props {
  className?: string;
}

const Footer: FC<Props> = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <SnackbarMessage />
      <ActionDialog />
    </div>
  );
};

export default Footer;
