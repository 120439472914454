import {
  AppBar,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Divider,
  Box,
  Avatar,
  SelectChangeEvent,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputIcon from '@mui/icons-material/Input';
import MenuIcon from '@mui/icons-material/Menu';
import { history } from 'redux/history';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import React, { FC, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Theme } from '@mui/material';
import SearchButton from 'components/AppKBar/SearchButton';
import { HEADER_HEIGHT } from 'assets/styles/constants';
import { ReactComponent as ResupplAILogo } from 'assets/logos/ResupplAI-Logo-Dark.svg';
import { useSelector } from 'react-redux';
import { RoutePaths } from 'constants/routePaths';
import { selectAuthenticatedUser } from 'redux/user/userSlice';
import DropdownSelect from 'components/inputs/selects/DropdownSelect';
import { useGetWarehousesQuery } from 'redux/warehouses/warehousesApi';
import { useUpdateUserMutation } from 'redux/user/userApi';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import { selectDisabledRoutes } from 'redux/settings/selectors';
import { State } from 'redux/store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    background: '#F5F5FE',
    height: HEADER_HEIGHT,
  },
  menuButton: {
    marginRight: theme.spacing(3),
    color: 'black',
  },
  accountButton: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: '24px',
  },
  profileHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  menuSection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const onClickLogout = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('token_bearer');
  localStorage.removeItem('expires_in');
  history.push(RoutePaths.Login);
};

interface Props {
  className?: string;
  onSidebarToggle: () => void;
  sidebarOpen: boolean;
}

const Topbar: FC<Props> = props => {
  const { className, onSidebarToggle, sidebarOpen, ...rest } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const authUser = useSelector(selectAuthenticatedUser);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<string>('');
  const [selectedWarehouseName, setSelectedWarehouseName] = useState<string>('');
  const { data: warehouses, isLoading, isError } = useGetWarehousesQuery();
  const [updateUser] = useUpdateUserMutation();
  const disabledRoutes = useSelector((state: State) => selectDisabledRoutes(state));

  const handleAccountClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccountClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (authUser?.warehouse_id && warehouses?.data) {
      const matchedWarehouse = warehouses.data.find(wh => wh.id === authUser.warehouse_id);
      if (matchedWarehouse) {
        setSelected(matchedWarehouse.id.toString());
        setSelectedWarehouseName(matchedWarehouse.name);
      }
    }
  }, [authUser, warehouses]);

  const handleOnWhSelectionChange = async (event: SelectChangeEvent<string>) => {
    const selectedWarehouseId = event.target.value;
    setSelected(selectedWarehouseId);

    try {
      await updateUser({
        ...authUser,
        warehouse_id: Number(selectedWarehouseId),
        warehouse_name: warehouses?.data.find(wh => wh.id === Number(selectedWarehouseId))?.name || null,
      }).unwrap();
      history.replace('/');
      window.location.reload();
    } catch (error) {
      console.error('Error updating user warehouse:', error);
    }
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
    window.location.reload();
  };

  return (
    <AppBar position="fixed" {...rest} className={classes.root}>
      <Toolbar>
        <IconButton
          data-test="test-burger"
          color="inherit"
          aria-label="open drawer"
          onClick={onSidebarToggle}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        <RouterLink to="/">
          <ResupplAILogo width={165} height="100%" />
        </RouterLink>
        <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 1 }}>
          <SearchButton />
          <IconButton onClick={handleAccountClick} className={classes.accountButton}>
            {!isSmallScreen && selectedWarehouseName && (
              <Typography variant="body1">{selectedWarehouseName}</Typography>
            )}
            <Avatar
              sx={{
                width: 24,
                height: 24,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <AccountCircleIcon sx={{ width: 24, height: 24 }} />
            </Avatar>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleAccountClose}
          MenuListProps={{
            sx: {
              padding: 2,
            },
          }}
        >
          <Box className={classes.profileHeader}>
            {isSmallScreen && <Typography variant="body1">{selectedWarehouseName}</Typography>}
            <Typography variant="subtitle1">{authUser?.email}</Typography>
            <Stack direction="row" spacing={1} justifyContent="center" marginBlockEnd={1}>
              <IconButton onClick={() => handleLanguageChange('lt-LT')}>
                <Avatar alt="Lithuanian" sx={{ width: 24, height: 24 }} src="/images/lithuania.png" />
              </IconButton>
              <IconButton onClick={() => handleLanguageChange('en-GB')}>
                <Avatar alt="English" sx={{ width: 24, height: 24 }} src="/images/united-kingdom.png" />
              </IconButton>
            </Stack>
          </Box>
          {!disabledRoutes.includes('warehouses') && (
            <Box className={classes.menuSection}>
              {isLoading ? (
                <Typography>{t('Is loading')}</Typography>
              ) : isError ? (
                <Typography color="error">{t('Unexpected error')}</Typography>
              ) : (
                <DropdownSelect
                  size="small"
                  values={warehouses?.data.map(wh => ({ label: wh.name, value: wh.id.toString() })) || []}
                  value={selected}
                  onChange={handleOnWhSelectionChange}
                  inputLabel={t('Warehouse')}
                />
              )}
            </Box>
          )}
          <Divider />
          <MenuItem onClick={onClickLogout}>
            <ListItemIcon>
              <InputIcon />
            </ListItemIcon>
            {t('Logout')}
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
