import { fetchClientSettings } from 'api/users';
import { State } from 'redux/store';
import { hasAPIError } from 'redux/api/thunks';
import { setLocalSettings, setSettings } from 'redux/settings/reducers';
import { PromiseThunk } from 'redux/utils/types';
import { LocalSettingsTypes, SettingsType } from './types';
import { selectSettings } from './selectors';
import { forecastingTablesVisibility } from './forecastingTablesSettings';
import _ from 'lodash';
import { selectAuthenticatedUser, selectUserGroupName, setUser } from 'redux/user/userSlice';
import { UserJSON } from 'redux/employees/types';

export const fetchAndSetSettings = (): PromiseThunk<State> => async (dispatch, getState) => {
  const authenticatedUser = selectAuthenticatedUser(getState());
  const response = await fetchClientSettings([
    {
      key: 'type',
      value: SettingsType.FRONT_END,
    },
  ]);
  const hasError = dispatch(hasAPIError(response));
  if (hasError || !response) {
    return;
  }
  const { headers } = response;
  const json = await response.json();
  // TODO: Remove this when the users/me endpoint will provide data about all users
  if (!authenticatedUser) {
    const email = headers.get('X-User-Email');
    const first_name = headers.get('X-User-First-Name');
    const last_name = headers.get('X-User-Last-Name');
    const username = headers.get('X-User-Username');
    const userGroup = headers.get('X-User-Group');
    const partialUser = {
      email,
      first_name,
      last_name,
      username,
      access_group: {
        name: userGroup,
      },
    };
    dispatch(setUser((partialUser as unknown) as UserJSON));
  }

  if (json?.data?.length) {
    const settings = JSON.parse(json.data[0]?.settings);
    settings.tables = _.merge(forecastingTablesVisibility, settings.tables);
    if (settings) {
      dispatch(setSettings(settings));
    }
  }
};

// On initial load use which modules are enabled setting, later on use local storage
// export const setInitialLocalSettings = (): PromiseThunk<State> => async (dispatch, getState: any) => {
//   const settings = selectSettings(getState());
//   return dispatch(
//     setLocalSettings({
//       svsModuleChecked:
//         localStorage.getItem(LocalSettingsTypes.SVS_MODULE_CHECKED) === null
//           ? settings.isSVSModuleEnabled
//           : localStorage.getItem(LocalSettingsTypes.SVS_MODULE_CHECKED) === 'true' && settings.isSVSModuleEnabled,
//       forecastingModuleChecked:
//         localStorage.getItem(LocalSettingsTypes.FORECASTING_MODULE_CHECKED) === null
//           ? settings.isForecastingModuleEnabled && !settings.isForecastingModuleEnabled // TODO: default to false
//           : localStorage.getItem(LocalSettingsTypes.FORECASTING_MODULE_CHECKED) === 'true' &&
//             settings.isForecastingModuleEnabled,
//     })
//   );
// };

export const setInitialLocalSettings = (): PromiseThunk<State> => async (dispatch, getState: any) => {
  const userGroup = selectUserGroupName(getState());
  const { isSVSModuleEnabled, isForecastingModuleEnabled } = selectSettings(getState());

  let svsModuleChecked =
    localStorage.getItem(LocalSettingsTypes.SVS_MODULE_CHECKED) === null
      ? isSVSModuleEnabled
      : localStorage.getItem(LocalSettingsTypes.SVS_MODULE_CHECKED) === 'true' && isSVSModuleEnabled;

  let forecastingModuleChecked =
    localStorage.getItem(LocalSettingsTypes.FORECASTING_MODULE_CHECKED) === null
      ? isForecastingModuleEnabled && !isForecastingModuleEnabled // Preserved logic in previous version, but that doesn't makes sense
      : localStorage.getItem(LocalSettingsTypes.FORECASTING_MODULE_CHECKED) === 'true' && isForecastingModuleEnabled;
  // TODO: automated po
  const isAutomatedPOEnabled = localStorage.getItem(LocalSettingsTypes.AUTOMATED_PO) === null ? false : true;
  const isManualPOEnabled = localStorage.getItem(LocalSettingsTypes.MANUAL_PO) === null ? false : true;
  // Adjust local settings based on user group
  if (userGroup === 'product_manager') {
    svsModuleChecked = false;
    forecastingModuleChecked = isForecastingModuleEnabled;
  }

  return dispatch(
    setLocalSettings({
      svsModuleChecked,
      forecastingModuleChecked,
      isAutomatedPOEnabled,
      isManualPOEnabled,
    })
  );
};

export const setLocalSetting = (key: string, value: any): PromiseThunk<State> => async (dispatch, getState: any) => {
  const { localSettings } = getState().settings;

  localStorage.setItem(key, value);

  dispatch(
    setLocalSettings({
      ...localSettings,
      [key]: value,
    })
  );
};
