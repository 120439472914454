import React, { FC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { connect, useDispatch } from 'react-redux';
import { setVisibility, ACTION_DIALOG_VISIBILITY_KEY } from 'redux/visibility/reducers';
import { createSelector } from 'reselect';
import { isMobile } from 'react-device-detect';
import { Dispatch } from 'redux/utils/types';
import { State } from 'redux/store';
import i18n from 'i18n';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useStyles } from './styles';

const mapDispatchToProps = (dispatch: Dispatch<State>) => ({
  hideDialog: () => dispatch(setVisibility({ key: ACTION_DIALOG_VISIBILITY_KEY, value: false })),
});

const selectDialogProps = createSelector(
  (state: State) => state.visibility[ACTION_DIALOG_VISIBILITY_KEY],
  dialogVisibility => {
    if (dialogVisibility && dialogVisibility.params) {
      const {
        title,
        secondaryTitle,
        onConfirmCallback,
        callbackParams,
        primaryTitle,
        contentText,
      } = dialogVisibility.params;
      return {
        isOpen: dialogVisibility.value,
        title,
        secondaryTitle,
        onConfirmCallback,
        callbackParams,
        primaryTitle,
        contentText,
      };
    }
    return { isOpen: false };
  }
);

const mapStateToProps = (state: State) => ({
  ...selectDialogProps(state),
});

interface Props {
  title: string;
  secondaryTitle: string;
  isOpen: boolean;
  hideDialog: () => void;
  onConfirmCallback: (callbackParams: string) => void;
  callbackParams: string;
  primaryTitle?: string;
  contentText?: string;
}

const ActionDialog: FC<Props> = ({
  title,
  secondaryTitle,
  isOpen,
  hideDialog,
  onConfirmCallback,
  callbackParams,
  primaryTitle = i18n.t('common:dialog.primaryTitleBack'),
  contentText,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    hideDialog();
  };
  const handleConfirm = () => {
    hideDialog();
    dispatch(onConfirmCallback(callbackParams));
  };
  const dialogTitleStyle = isMobile ? { maxWidth: 300, alignSelf: 'center' } : { alignSelf: 'center' };
  return isOpen ? (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <DialogTitle style={dialogTitleStyle}>{title}</DialogTitle>
        {contentText && (
          <DialogContent className={classes.dialogContent}>
            <DialogContentText>{contentText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose} color="secondary" data-test="cancel-dialog-button">
            {primaryTitle}
          </Button>
          <Button onClick={handleConfirm} color="primary" data-test="confirm-dialog-button">
            {secondaryTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : null;
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionDialog);
