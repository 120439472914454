import { LocalSettings } from '../redux/settings/types';

// Merge available column settings: clientSettings coming from api always preferred over local settings
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const merge = (columns: any, columnSettings: any, key: string, localSettings?: LocalSettings) => {
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore
  // const svsCheckedColumnsSettings = svsModuleCheckedTableColumnsVisibility[key];
  // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // // @ts-ignore
  // const forecastingColumnsSettings = forecastingModuleCheckedTableColumnsVisibility[key];

  return columns.map((column: any) => {
    const identifier = column.id ? column.id : column.field;
    // const modulesVisibility = [];

    const clientSettingsVisibility = columnSettings?.find((s: any) => s?.id === identifier || s?.field === identifier);

    // if (localSettings?.svsModuleChecked) {
    //   const columnVisibility = svsCheckedColumnsSettings?.find(
    //     (s: any) => s?.id === identifier || s?.field === identifier
    //   );
    //
    //   modulesVisibility.push({
    //     identifier,
    //     hidden: columnVisibility ? columnVisibility.hidden : false,
    //   });
    // }
    //
    // if (localSettings?.forecastingModuleChecked) {
    //   const columnVisibility = forecastingColumnsSettings?.find(
    //     (s: any) => s?.id === identifier || s?.field === identifier
    //   );
    //
    //   modulesVisibility.push({
    //     identifier,
    //     hidden: columnVisibility ? columnVisibility.hidden : false,
    //   });
    // }

    return {
      ...column,
      // If at least one module unhides that column - leave it visible
      // ...(modulesVisibility.length
      //   ? {
      //       field: identifier,
      //       hidden: modulesVisibility.every(mv => mv.hidden),
      //     }
      //   : {}),
      ...clientSettingsVisibility,
    };
  });
};
